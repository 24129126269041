<div class="video-box">
    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
        <a
            data-lg-size="1280-720"
            data-src="https://www.youtube.com/watch?v=24BXOFpIW6w?rel=0"
            data-sub-html="<p>Watch Video</p>"
            class="d-block"
        >
            <div class="image">
                <img src="assets/img/dotted.png" class="shadow" alt="image">
            </div>
            <div class="video-btn popup-youtube">
                <i class="flaticon-play"></i>
            </div>
        </a>
    </lightgallery>
    <div class="shape10"><img src="assets/img/dotted.png" alt="image"></div>
</div>