import { Component } from '@angular/core';

@Component({
  selector: 'app-register-successfully',
  templateUrl: './register-successfully.component.html',
  styleUrl: './register-successfully.component.scss'
})
export class RegisterSuccessfullyComponent {

}
