import { Component } from '@angular/core';
import { Router } from '@angular/router';
import constant from 'src/app/app.constant';

@Component({
  selector: 'app-animation-data-section',
  // standalone: true,
  // imports: [],
  templateUrl: './animation-data-section.component.html',
  styleUrl: './animation-data-section.component.scss'
})
export class AnimationDataSectionComponent {
  constructor(private router: Router) {
  }
  customerForm() {
    this.router.navigate(['/customer/'+ constant.Service.MainLogin.integrateId])
  }
}
