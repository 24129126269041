<!-- adloggs version 0.1 -->
<div class="about-area-three ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box ">
                    <h2>Our (3PL) Third-Party Logistics Partners</h2>
                    <p>Adloggs provides a single platform that streamlines delivery operations. From order processing to route optimization, everything is integrated. Digitization: By encouraging businesses to digitize their delivery processes, improves efficiency and reduces paperwork. Real-time data ensures better decision-making.</p>
                    <p>Aggregation: Adloggs aggregates delivery services, allowing businesses to tap into a network of reliable carriers. This minimizes delays and optimizes routes.</p>
                    <a routerLink="/partner-dms" class="default-btn">Create Your Own DMS <span></span></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="ai-3pl-container">
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/dunzo.png" alt="3plDunzo">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/shadowfox.png" alt="3plShadowfox">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/Grab.png" alt="3plGrab">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/shiprocket.png" alt="3plShiprocket">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/zomato.png" alt="3plZomato">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/borzo.png" alt="3plBorzo">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/flash.png" alt="3plFlash">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/rapito.png" alt="3plRapito">
                    </div>                    
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/qwqer.png" alt="3plQwqer">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/ondc.png" alt="3plOndc">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/ola.png" alt="3plOla">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/loadshare.png" alt="3plLoadshare">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/porter.png" alt="3plPorter">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/get-delivery.png" alt="3plNowDelivery">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/opoli-ezip.png" alt="3plOpoliEzip">
                    </div>
                    <div class="ai-3pl-wrapper">
                        <img src="assets/img/3pl/zypp.png" alt="3plZypp">
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>