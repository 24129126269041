import { Component } from '@angular/core';

@Component({
  selector: 'app-threepl-section',
  // standalone: true,
  // imports: [],
  templateUrl: './threepl-section.component.html',
  styleUrl: './threepl-section.component.scss'
})
export class ThreeplSectionComponent {

}
