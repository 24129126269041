<div class="faq-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-content-box">
                    <h2>Get Answers on Everything About <br class="ai-break"/>Delivery Management</h2>                    
                </div>
            </div>
        </div>
        <div class="faq-accordion">
            <div class="accordion">
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(0)" [ngClass]="{ 'open': isSectionOpen(0) }">
                        Which are the delivery companies that you guys partner with?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                        <div class="row">
                            <div class="col-12">
                                <div class="ai-3pl-container">
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/dunzo.png" alt="3plDunzo">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/shadowfox.png" alt="3plShadowfox">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/Grab.png" alt="3plGrab">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/borzo.png" alt="3plBorzo">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/flash.png" alt="3plFlash">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/rapito.png" alt="3plRapito">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/zomato.png" alt="3plZomato">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/qwqer.png" alt="3plQwqer">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/ondc.png" alt="3plOndc">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/ola.png" alt="3plOla">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/loadshare.png" alt="3plLoadshare">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/porter.png" alt="3plPorter">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/get-delivery.png" alt="3plNowDelivery">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/opoli-ezip.png" alt="3plOpoliEzip">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/zypp.png" alt="3plZypp">
                                    </div>
                                    <div class="ai-3pl-wrapper">
                                        <img src="assets/img/3pl/adloggs.png" alt="3plAdloggs">
                                    </div>
                                </div>

                                <p class="mt-4 mb-0">In addition the industry leaders mentioned here we also have more than 100 small delivery agencies across India.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }">
                        Is Adloggs better than Pidge or U-Engage?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                        <p>Oh, we’re not here to start a food fight! Adloggs and Pidge are more like peanut butter and jelly—both awesome in their own way and even better together. We both want what’s best for you, our client. Why settle for one flavour when you can have the whole delicious sandwich? Give both a try and enjoy the best of both worlds</p>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(2) }">
                        My delivery volume is very low what benefits would I have partnering with Adloggs?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                        <p>Even the greatest innings of all time would have started with a single or a block. Low volumes need to catered well enough to increase and build a steady flow. We understand that and Adloggs encourages low volume businesses to partner with Adloggs and build their volumes while getting competitive delivery rates for their orders.</p>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(3)" [ngClass]="{ 'open': isSectionOpen(3) }">
                        What types of businesses can use Adloggs?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                        <p>Adloggs is versatile and caters to a variety of businesses including restaurants, grocery stores, pharmacies, and more. Whether you have a few orders a month or thousands a day, we’ve got you covered.</p>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(4)" [ngClass]="{ 'open': isSectionOpen(4) }">
                        I have partnered with Dunzo and Flash and they have given me great pricing can you match that? 
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                        <p>No issues if you have better pricing from Dunzo and Flash than what we have, we will directly connect your Adloggs account with them so that you have best of both worlds. You can continue using your account with them with their prices and when they are not available you can use other 3Pls.</p>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(5)" [ngClass]="{ 'open': isSectionOpen(5) }">
                        Is Adloggs’ Delivery Management System (DMS) secure?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(5) }">
                        <p>Absolutely! Our DMS is 100% secure, certified, and white-labeled to reflect your brand. We prioritize your data’s safety and ensure features are on par with the best systems in the world.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
