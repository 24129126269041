import { Component } from '@angular/core';

@Component({
  selector: 'app-impact-of-late-and-inaccurate-deliveries',
  // standalone: true,
  // imports: [],
  templateUrl: './impact-of-late-and-inaccurate-deliveries.component.html',
  styleUrl: './impact-of-late-and-inaccurate-deliveries.component.scss'
})
export class ImpactOfLateAndInaccurateDeliveriesComponent {

}
