<div class="slogan-area mtb-100 bg-fffaf3 ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="slogan-image">
                    <img src="assets/img/man2.jpg" alt="image" />
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="slogan-content">
                    <p>
                        Our project management training equips learners with the
                        knowledge and discipline required to effectively plan,
                        manage, execute, and control projects regardless of
                        industry. You'll learn all about the most popular
                        project management methodologies that help organizations
                        deliver successful projects.
                    </p>
                    <h3>William James</h3>
                    <span class="sub-title">CEO at adloggs</span>
                </div>
            </div>
        </div>
    </div>
    <div class="divider2"></div>
    <div class="divider3"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image" /></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image" /></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>
