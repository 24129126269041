import { environment } from "src/environments/environment";

class Constant {

    InternalError = 'Internal Error';
    PageSize = 20;
    // localhost:40211/user/
    localStorage = {
        prefix: 'addloggs-',
        token: 'token',
        //     user_name: 'user_name',
        //     user_role: 'user_role',
        //     user_role_id: 'user_role_id',
        user_id: 'user_id'
    };



    Service = {
        MainLogin: {
            BaseUrl: environment.Service.AdloggsApiBaseUrl,
            integrateId:environment.Service.appId,
            // BaseUrl: 'https://app.adloggs.com/aa/',
            // BaseUrl: 'https://192.168.0.104:4000/aa/',
            Auth: {
                Login: 'user/login',
            },
            City: {
                Create: 'city',
                Update: 'city',
                CheckCity: 'city/checkavailable',
                CheckAgecncyArea: 'city/check/agencyarea',
                AddCity: 'city/createfromtext'
            },
            Dynamic: {
                getAll: 'dynamic',
                GetOne: 'dynamic/',
            },
            User: {
                Create: 'user',
                Update: 'user',
                GetAll: 'user',
                GetById: 'user/',
                Session: 'user/session/json',
                emailCheck: 'user/check/email',
                PhoneCheck: 'ds/check/phone',
                DesktopUpdateToken: 'user/updateuser/own',
                forgetPassword: 'user/pwresetcode',
                updatePassword: 'user/resetpw',
                otpVerify: 'user/onboard/otpverify'
            },
            Vendor: {
                Create: 'user/onboard/otp'
            }
        },
    }

    map = {
        mapsUrl: 'https://maps.googleapis.com/maps/api'
    };

}

let constant = new Constant();
export default constant;