import { Component } from '@angular/core';
import { Router } from '@angular/router';
import constant from 'src/app/app.constant';

@Component({
  selector: 'app-home',
  // standalone: true,
  // imports: [],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  constructor(private router: Router) {
  }
  customerForm() {
    this.router.navigate(['/customer/'+constant.Service.MainLogin.integrateId])
  }
}
