<!-- adloggs version 0.1 -->

<div class="banner-wrapper-area">
    <div class="container">
        <div class="banner-inner-area">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon">
                            <i class="flaticon-brain-process"></i>
                        </div>
                        <h3>
                            <strong>12M</strong>
                        </h3>
                        <p>Successfully Delivered</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon">
                            <i class="flaticon-people"></i>
                        </div>
                        <h3>
                            <strong>215K</strong>
                        </h3>
                        <p>Stores</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon">
                            <i class="flaticon-people"></i>
                        </div>
                        <h3>
                            <strong>2.2K</strong>
                        </h3>
                        <p>Clients</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon">
                            <i class="flaticon-world"></i>
                        </div>
                        <h3>
                            <strong>2.2M</strong>
                        </h3>
                        <p>Customers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>