import { Component, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent {
  // form: any;
  // router: any;

  // constructor() { }

  // ngOnInit(): void {
  // }

  //isChecked = false;

  // submit(form){
  //     var name = form.name;
  //     console.log(name);

  //     var email = form.email;
  //     console.log(email);

  //     var number = form.number;
  //     console.log(number);

  //     var message = form.message;
  //     console.log(message);
  // }

  isChecked = false;

  @ViewChild("webform4164692000003345004") form!: ElementRef<HTMLFormElement>;
    captchaSrc: string;
    
  constructor(private toaster: ToastrService, private router: Router) {
    this.captchaSrc = this.getCaptchaSrc();
  }
    
  getCaptchaSrc(): string {
    const baseSrc =
      "https://crm.zoho.com/crm/CaptchaServlet?formId=3ea58e653ffc7718cc78da74853e766014f8cd32c764eeb9c51eed1e2538ffb1c4565961a310cd31062479b374752bcf&grpid=157babfcd8fe97037ab475576600dbb02c5c2c96f9e484dafdd67010587539b4";
    return baseSrc + "&d=" + new Date().getTime();
  }
  

  reloadCaptcha(): void {
    this.captchaSrc = this.getCaptchaSrc();
  }
  onCheckboxChange(event: any) {
    this.isChecked = event.target.checked;
  }
//   ngAfterViewInit() {
//     console.log("Form reference:", this.form);
//   }

  onSubmit(): void {
    if (this.checkMandatory4164692000003345004()) {
      // Submits the form to Zoho CRM
      if (this.form) {
        this.form.nativeElement.submit();
        // setTimeout(() => {
        //   this.router.navigate(["/partner-dms"]);
        //   window.addEventListener(
        //     "focus",
        //     this.historyBack4164692000001256004.bind(this)
        //   );
        // }, 2000);
      } else {
        //console.error('Form is not available.');
        this.toaster.error("Form is not available.");
      }
    }
  }

//   reloadImg4164692000003345004(): void {
//     const captcha = document.getElementById(
//       "imgid4164692000003345004"
//     ) as HTMLImageElement;
//     if (captcha.src.indexOf("&d") !== -1) {
//       captcha.src =
//         captcha.src.substring(0, captcha.src.indexOf("&d")) +
//         "&d" +
//         new Date().getTime();
//     } else {
//       captcha.src = captcha.src + "&d" + new Date().getTime();
//     }
//   }

  historyBack4164692000001256004(): void {
    const submitButton = document.querySelector(
      ".crmWebToEntityForm .formsubmit"
    ) as HTMLButtonElement;
    submitButton.removeAttribute("disabled");
    this.reloadCaptcha();
    window.removeEventListener("focus", this.historyBack4164692000001256004);
  }

  validateEmail4164692000003345004(): boolean {
    const form = document.forms[
      "WebToContacts4164692000003345004"
    ] as HTMLFormElement;
    const emailFld = form.querySelectorAll(
      "[ftype=email]"
    ) as NodeListOf<HTMLInputElement>;
    for (let i = 0; i < emailFld.length; i++) {
      const emailVal = emailFld[i].value;
      if (emailVal.replace(/^\s+|\s+$/g, "").length !== 0) {
        const atpos = emailVal.indexOf("@");
        const dotpos = emailVal.lastIndexOf(".");
        if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
          //alert('Please enter a valid email address.');
          this.toaster.error("Please enter a valid email address.");
          emailFld[i].focus();
          return false;
        }
      }
    }
    return true;
  }

  checkMandatory4164692000003345004(): boolean {
    const mndFileds = ["Last Name", "Email", "Phone"];
    const fldLangVal = ["First\x20Name", "Email", "Phone"];
    for (let i = 0; i < mndFileds.length; i++) {
      const fieldObj = document.forms["WebToContacts4164692000003345004"][
        mndFileds[i]
      ] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
      if (fieldObj) {
        if (fieldObj.value.replace(/^\s+|\s+$/g, "").length === 0) {
          if (fieldObj.type === "file") {
            //alert('Please select a file to upload.');
            this.toaster.error("Please select a file to upload.");
            fieldObj.focus();
            return false;
          }
          this.toaster.error(fldLangVal[i] + " cannot be empty.");
          fieldObj.focus();
          return false;
        } else if (fieldObj.nodeName === "SELECT") {
          if (
            (fieldObj as HTMLSelectElement).options[
              (fieldObj as HTMLSelectElement).selectedIndex
            ].value === "-None-"
          ) {
            //alert(fldLangVal[i] + ' cannot be none.');
            this.toaster.error(fldLangVal[i] + " cannot be none.");
            fieldObj.focus();
            return false;
          }
        } else if (fieldObj.type === "checkbox") {
          if (!(fieldObj as HTMLInputElement).checked) {
            //alert('Please accept ' + fldLangVal[i]);
            this.toaster.error("Please accept " + fldLangVal[i]);
            fieldObj.focus();
            return false;
          }
        }
        try {
          if (fieldObj.name === "Last Name") {
            const name = fieldObj.value; // `name` variable is declared but never used
          }
        } catch (e) {
          console.error(e);
        }
      }
    }

    if (!this.validateEmail4164692000003345004()) {
      return false;
    }

    const urlparams = new URLSearchParams(window.location.search);
    if (urlparams.has("service") && urlparams.get("service") === "smarturl") {
      const webform = document.getElementById(
        "webform4164692000003345004"
      ) as HTMLFormElement;
      const service = urlparams.get("service")!;
      const smarturlfield = document.createElement("input");
      smarturlfield.setAttribute("type", "hidden");
      smarturlfield.setAttribute("value", service);
      smarturlfield.setAttribute("name", "service");
      webform.appendChild(smarturlfield);
    }

    const formSubmit = document.querySelector(
      ".crmWebToEntityForm .formsubmit"
    ) as HTMLButtonElement;
    formSubmit.setAttribute("disabled", "true");
    window.addEventListener("focus", this.historyBack4164692000001256004);
    return true;
  }

  tooltipShow4164692000003345004(el: HTMLElement): void {
    const tooltip = el.nextElementSibling as HTMLElement;
    const tooltipDisplay = tooltip.style.display;
    if (tooltipDisplay === "none") {
      const allTooltip = document.getElementsByClassName(
        "zcwf_tooltip_over"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < allTooltip.length; i++) {
        allTooltip[i].style.display = "none";
      }
      tooltip.style.display = "block";
    } else {
      tooltip.style.display = "none";
    }
  }
}
