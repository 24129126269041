
export class CommonLogicService {

    public getUtcOffset() {
        return new Date().getTimezoneOffset();
    }

    public minimumError(fieldName: any, Value: any) {
        return `${fieldName} must be at least ${Value} characters long`
    }
    public maximumError(fieldName: any, Value: any) {
        return `${fieldName} should be no longer than ${Value} characters`
    }
    public maxAllowValueError(Value: any) {
        return `Please enter a value less than ${Value}`
    }

}

let commonLogics = new CommonLogicService();
export default commonLogics;
