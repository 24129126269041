<!-- Step-1 -->
<div class="ad-form-container">
    <div class="ad-left-banner res-mobile-none" style="background-image: url(assets/img/customerBanner.jpg);">
        <a routerLink="/" class="back-btn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path
                    d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z">
                </path>
            </svg>
            <h6 class="mb-0"> Back</h6>
        </a>
        <h2 class="mb-0"><strong>Manage your</strong> Deliveries Stress-Free!</h2>
    </div>
    <div class="ad-right-container">
        <!-- back btn for mobile -->
        <div class="ad-mobile-back-btn">
            <a routerLink="/" class="back-btn">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path
                        d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z">
                    </path>
                </svg>
            </a>
        </div>
        <div class="ad-text-container">
            <h1>Welcome <span class="main-color">Aboard,</span></h1>
            <p>#Deliveries, Simplified.</p>
        </div>
        <!-- single or multistore -->
        <!-- <div class="ad-single-multi-store-container" style="display: none;">
            <h4>Please choose your store type.</h4>
            <div class="ai-response-container">
                <a routerLink="/" class="ai-box">
                    <div class="ai-meta-loader">
                    </div>
                    <p class="mb-0">Single Store</p>
                </a>
                <a routerLink="/" class="ai-box">
                    <div class="ai-meta-loader">

                    </div>
                    <p class="mb-0">Multi Stores</p>
                </a>
            </div>
        </div> -->
        <!-- single store -->
        <div class="ad-single-store-container">
            <form [formGroup]="customerFormGroup" autocomplete="off" (ngSubmit)="submit()">
                <div class="row g-2">
                    <div class="col-12 mt-0">
                        <div class="d-flex flex-row justify-content-start align-items-center gap-1 mb-3 mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24"
                                height="24">
                                <path
                                    d="M17.0839 15.812C19.6827 13.0691 19.6379 8.73845 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.36205 8.73845 4.31734 13.0691 6.91612 15.812C7.97763 14.1228 9.8577 13 12 13C14.1423 13 16.0224 14.1228 17.0839 15.812ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9C15 10.6569 13.6569 12 12 12Z">
                                </path>
                            </svg>
                            <h5 class="mb-0"><strong>Basic Info</strong></h5>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 mt-0">
                        <label for="name">Your Name <span class="ad-manto-sys">*</span></label>
                        <div class="form-group">
                            <input class="form-control" type="text" pattern="[a-zA-Z][a-zA-Z0-9 ]*"
                                placeholder="Enter your name" formControlName="first_name">

                            <div *ngIf="customerFormGroup.get('first_name').errors?.required && submitted"
                                class="alert alert-danger">
                                Name is required
                            </div>
                            <div *ngIf="customerFormGroup.get('first_name')?.dirty" class="alert alert-danger">
                                <div *ngIf="customerFormGroup.get('first_name')?.errors?.maxlength">Customer name
                                    cannot be
                                    more
                                    than
                                    100
                                    characters long.</div>
                            </div>
                            <div *ngIf="customerFormGroup.get('first_name').errors?.pattern && (customerFormGroup.get('first_name')?.dirty || submitted)"
                                class="alert alert-danger">
                                Must start with a letter and contain only letters, digits, and spaces
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 mt-0">
                        <label for="gst">GST Number <span class="ad-manto-sys">*</span></label>
                        <div class="form-group">
                            <input class="form-control" type="text" minlength=15 maxlength=15
                                (input)="onInputChange($event)" placeholder="Enter GST number" formControlName="gst_no">
                                <!-- {{customerFormGroup.get('gst_no')?.errors?.minlength?.requiredLength | json}} -->
                                <div *ngIf="customerFormGroup.get('gst_no').errors?.required && submitted"
                                class="alert alert-danger">
                                GST number is required
                            </div>
                            <div *ngIf="customerFormGroup.get('gst_no')?.errors?.minlength?.requiredLength" class="alert alert-danger">
                                The GST number should be 15 characters long.
                            </div>
                            <div class="alert alert-danger"
                                *ngIf="customerFormGroup.get('gst_no')?.errors?.pattern && (customerFormGroup.get('gst_no')?.dirty || submitted)">
                                Only letters and numbers are allowed.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 mt-0">
                        <label for="email-id">Email ID <span class="ad-manto-sys">*</span></label>
                        <div class="form-group">
                            <input class="form-control" type="email" placeholder="Enter email address"
                                formControlName="email" (blur)="getEmailCheck()" (input)="onInputChangeEmail($event)">
                            <div class="alert alert-danger">
                                <div
                                    *ngIf="customerFormGroup.get('email')?.errors?.pattern && (customerFormGroup.get('email')?.dirty || submitted)">
                                    Please enter a valid email address.
                                </div>
                                <!-- <div *ngIf="customerFormGroup.get('email')?.errors?.minlength">
                                    Email must be at least 10 characters long.</div> -->
                                <div *ngIf="customerFormGroup.get('email')?.errors?.maxlength">
                                    Email cannot be more than 90 characters long.</div>
                                <div *ngIf="customerFormGroup.get('email')?.errors?.required && submitted">
                                    Email is required.
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 mt-0">
                        <label for="contact_number">Contact number <span class="ad-manto-sys">*</span></label>
                        <div class="form-group">
                            <input class="form-control" numbersOnly minlength=10 maxlength=10 type="text"
                                placeholder="Enter contact number" formControlName="phone">
                            <div *ngIf="customerFormGroup.get('phone')?.errors?.required && submitted"
                                class="alert alert-danger">
                                Phone number is required
                            </div>
                            <div *ngIf="customerFormGroup.get('phone')?.errors?.minlength?.requiredLength"
                                class="alert alert-danger">
                                Phone number should be 10 characters long.
                            </div>
                            <div *ngIf="customerFormGroup.get('phone')?.errors?.pattern && (customerFormGroup.get('phone')?.dirty || submitted)"
                                class="alert alert-danger">
                                Only numbers are allowed and must be 10 digits
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-0">
                        <div class="d-flex flex-row justify-content-start align-items-center gap-1 mb-3 mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24"
                                height="24">
                                <path
                                    d="M2 5L9 2L15 5L21.303 2.2987C21.5569 2.18992 21.8508 2.30749 21.9596 2.56131C21.9862 2.62355 22 2.69056 22 2.75827V19L15 22L9 19L2.69696 21.7013C2.44314 21.8101 2.14921 21.6925 2.04043 21.4387C2.01375 21.3765 2 21.3094 2 21.2417V5ZM15 19.7639V7.17594L14.9352 7.20369L9 4.23607V16.8241L9.06476 16.7963L15 19.7639Z">
                                </path>
                            </svg>
                            <h5 class="mb-0"><strong>Address</strong></h5>
                        </div>
                    </div>
                    <div class="col-12 map mt-0">
                        <!-- <div class="ad-map-container">
                            <div id="map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d260370.4624153429!2d-74.21074577271956!3d40.67735855419542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25090129c363d%3A0x40c6a5770d25022b!2sStatue%20of%20Liberty%20National%20Monument!5e0!3m2!1sen!2sbd!4v1599386259810!5m2!1sen!2sbd"></iframe>
                            </div>
                        </div> -->
                        <div class="map-container">
                            <div class="map-wrapper" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
                                <div #mapContainer class="map-container"></div>
                                <div class="map-center-overlay">
                                    <img src="assets/img/icons/pin.png" width="35" />
                                </div>
                                <div class="map-top-input-overlay">
                                    <div class="form-group">
                                        <input class="form-control" id="ship-address" name="ship-address"
                                            placeholder="Enter the location" formControlName="address"
                                            autocomplete="off" (input)="onSearchChange($event)" />
                                    </div>
                                    <button type="button" class="btn btn-primary ad-current-location"
                                        (click)="handlePermission()">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" witdh="24"
                                            height="24" fill="currentColor">
                                            <path
                                                d="M11 5.07089C7.93431 5.5094 5.5094 7.93431 5.07089 11H7V13H5.07089C5.5094 16.0657 7.93431 18.4906 11 18.9291V17H13V18.9291C16.0657 18.4906 18.4906 16.0657 18.9291 13H17V11H18.9291C18.4906 7.93431 16.0657 5.5094 13 5.07089V7H11V5.07089ZM3.05493 11C3.51608 6.82838 6.82838 3.51608 11 3.05493V1H13V3.05493C17.1716 3.51608 20.4839 6.82838 20.9451 11H23V13H20.9451C20.4839 17.1716 17.1716 20.4839 13 20.9451V23H11V20.9451C6.82838 20.4839 3.51608 17.1716 3.05493 13H1V11H3.05493ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z">
                                            </path>
                                        </svg>
                                        <span>Current Location</span>
                                    </button>
                                </div>
                                <div class="map-dragg">Move the map to fine-tune its position</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-0">
                        <div *ngIf="allowPermission" class="alert alert-danger">Please allow the location for browser
                            site settings</div>
                        <div *ngIf="customerFormGroup.get('address').errors?.required && submitted"
                            class="alert alert-danger">
                            Address is required
                        </div>
                        <div *ngIf="customerFormGroup.get('google_address_dtl')?.errors?.required && submitted"
                            class="alert alert-danger">
                            Please choose from google list and Move the map to the position of your address
                        </div>
                    </div>
                    <div class="col-12 mt-0">
                        <div class="form-check mt-2 mb-2">
                            <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                                (change)="onCheckboxChange($event)" formControlName="termsAndCondition">
                            <label class="form-check-label" for="flexCheckDefault">
                                I agree to the <a routerLink="/terms-and-conditions" target="_blank">Terms and
                                    Conditions</a> & <a routerLink="/privacy-policy" target="_blank">Privacy
                                    Policy</a>
                            </label>
                            <div *ngIf="termsError" class="alert alert-danger">
                                Please indicate that you have read and agree to the Terms and Conditions.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mt-0">
                        <div class="d-flex flex-row justify-content-end align-items-center gap-1 mb-2">
                            <button class="btn btn-cancel" type="reset">Cancel</button>
                            <button class="btn btn-primary" type="submit" [disabled]="disableBtn">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Step-2 -->


<ng-template #content let-modal>
    <div class="ad-form-container ad-form-container-step-1" [class.otp-success]="otpSuccess">
        <div class="ad-left-banner">
            <a (click)="closeFun()" class="back-btn">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path
                        d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z">
                    </path>
                </svg>
                <h6 class="mb-0">Close</h6>
            </a>
            <div class="ad-opt-container">
                <img src="assets/img/otpImage.png" alt="otpImage">
                <h1><strong>OTP Verification</strong></h1>
                <p>We have sent an OTP code to your Phone number <br class="ai-break" /> <span>{{phoneNumber}}</span>
                    Enter the OTP code below to verify.</p>
                <div class="otp-input">
                    <!-- <input type="number" min="0" max="9" required>
                    <input type="number" min="0" max="9" required>
                    <input type="number" min="0" max="9" required>
                    <input type="number" min="0" max="9" required>
                    <input type="number" min="0" max="9" required>
                    <input type="number" min="0" max="9" required> -->
                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
                </div>
                <div *ngIf="otpError">{{otpMessage}}</div>
                <p>Didn’t receive Code? <a href="/"> Resend OTP</a></p>
                <div class="d-block">
                    <button class="btn btn-otp text-center" type="button" (click)="onSubmit()"> Verify OTP </button>
                </div>
            </div>
            <h2 class="mb-0"><strong>Manage your</strong> Deliveries Stress-Free!</h2>
        </div>
        <div class="ad-last-banner">
            <h3 class="mb-2">Product Suite</h3>
            <embed 
            src="assets/img/adloggs-product.pdf"
            type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
            />
        </div>
        <div class="ad-right-container">
            <!-- pre loader -->
            <div class="ad-pre-loader">
                <object data="assets/img/AdMainLoader.svg" alt="about-adloggs"></object>
            </div>
            <!-- pre loader -->
            <div class="ad-text-container ad-welcome-mess">
                <h1>Dear <span class="main-color"> {{customerFormGroup.get('first_name')?.value}},</span></h1>
                <p>We are thrilled to introduce Adloggs, a leading logistics platform designed to streamline your
                    delivery
                    operations.</p>
                <p>🚛 Our hybrid delivery solution has already proven successful with industry leaders like KFC, and we
                    are
                    confident it can benefit your business as well.</p>

                <h5><strong>About Adloggs</strong></h5>
                <p>Adloggs integrates multiple delivery partners (Shadowfax, Dunzo, Rapido, Zomato, Porter, etc.) and
                    over
                    100 agencies to ensure seamless and efficient deliveries. 📦✨ Our platform centralizes order
                    management,
                    optimizing delivery speed and cost.</p>

                <h5><strong>Success with KFC</strong></h5>

                <p><strong>Key Achievements:</strong></p>
                <p>🚀 Enhanced delivery speed and customer satisfaction</p>
                <p>🔄 Adaptability to changing market demands</p>
                <p>📈 Scalable and efficient delivery operations</p>

                <h5><strong>Benefits for Your Business</strong></h5>

                <p>Increased Efficiency: Streamlined order routing to the best delivery partners 📈</p>
                <p>Scalability: Easily scale operations to meet demand 🌐</p>
                <p>Cost-Effectiveness: Optimize delivery costs 💰</p>
                <p>Improved Customer Experience: Faster, more reliable deliveries ✨</p>

                <h5><strong>Next Steps</strong></h5>

                <p>We would love to discuss how Adloggs can benefit your business. Please let us know a convenient time
                    for
                    a <a href="https://adloggstechnologies.zohobookings.com/#/adloggstechnologies" target="_blank">
                        <strong>meeting (click on meeting to schedule)</strong></a> or call 86081 86083. Alternatively,
                    if
                    you are keen to access our API documentation please click #
                    Thank you for considering Adloggs as your logistics partner. </p>
                <div class="d-flex flex-row justify-content-end align-items-center mb-2">
                    <a (click)="doneFun()" href="https://app.adloggs.com/" target="_blank" class="btn btn-primary btn-all-done"> 
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor">
                            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path>
                        </svg>
                        <span> Login Now </span>
                    </a>
                </div>
            </div>
        </div>       
    </div>
</ng-template>