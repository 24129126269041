import { Component } from '@angular/core';

@Component({
  selector: 'app-successfully-delivered',
  // standalone: true,
  // imports: [],
  templateUrl: './successfully-delivered.component.html',
  styleUrl: './successfully-delivered.component.scss'
})
export class SuccessfullyDeliveredComponent {

}
