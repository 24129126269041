<div
    class="navbar-area"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.svg" alt="adloggs-logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <!-- <form class="search-box">
                    <input type="text" class="input-search" placeholder="Search for anything">
                    <button type="submit"><i class="flaticon-search"></i></button>
                </form> -->
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link">Home</a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Business <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">                            
                            <li class="nav-item"><a routerLink="/business" [class.active]="activeFragment === 'Customer'" fragment="Customer" class="nav-link" (click)="setActiveFragment('Customer')">Customer</a></li>
                            <li class="nav-item"><a routerLink="/business" [class.active]="activeFragment === 'Partner'" fragment="Partner" class="nav-link" (click)="setActiveFragment('Partner')">Partner</a></li>
                            <li class="nav-item"><a routerLink="/business" [class.active]="activeFragment === 'DMS'" fragment="DMS" class="nav-link" (click)="setActiveFragment('DMS')"    >Delivery Management System</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/solutions" class="nav-link">Solutions</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/company" class="nav-link">Company</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/blogs" class="nav-link">Blogs</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact-us" class="nav-link">Contact us</a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link" (click)="toggleClass()">Home</a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Business <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">                            
                            <li class="nav-item"><a routerLink="/business" [class.active]="activeFragment === 'Customer'" fragment="Customer" class="nav-link" (click)="setActiveFragment('Customer')" (click)="toggleClass()">Customer</a></li>
                            <li class="nav-item"><a routerLink="/business" [class.active]="activeFragment === 'Partner'" fragment="Partner" class="nav-link" (click)="setActiveFragment('Partner')" (click)="toggleClass()">Partner</a></li>
                            <li class="nav-item"><a routerLink="/business" [class.active]="activeFragment === 'DMS'" fragment="DMS" class="nav-link" (click)="setActiveFragment('DMS')" (click)="toggleClass()">Delivery Management System</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/solutions" class="nav-link" (click)="toggleClass()">Solutions</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/company" class="nav-link" (click)="toggleClass()">Company</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/blogs" class="nav-link" (click)="toggleClass()">Blogs</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact-us" class="nav-link" (click)="toggleClass()">Contact us</a>
                    </li>
                </ul>
            </div>
            <div class="others-option d-flex align-items-center">
                <!-- <div class="option-item">
                    <div class="cart-btn">
                        <a routerLink="/cart"><i class='flaticon-shopping-cart'></i><span>3</span></a>
                    </div>
                </div> -->
                <div class="option-item">
                    <a (click)="customerForm()" class="default-btn cursor-pointer">Join Now<span></span></a>
                </div>
            </div>
        </nav>
    </div>
</div>