<!-- adloggs version 0.1 -->
<app-home-banner></app-home-banner>
<div class="partner-area">
    <div class="container-fluid ai-container-fuild">
        <div class="ai-container">
            <div class="row">
                <div class="col-12">
                    <div class="ai-form-container">
                        <div class="ai-form-wrapper">
                            <div class="ai-hi-text">
                                <img src="assets/img/icons/hi-icon.png" alt="ad-hi-icon">
                            </div>
                            <h3 class="mb-0">Hi, Are you here for?</h3>
                            <div class="ai-wrapper">
                                <div class="ai-icon">
                                    <img src="assets/img/icons/ai-icon.svg" alt="ad-ai-icon">
                                </div>
                                <div class="ai-loader-animation"></div>
                            </div>
                        </div>
                        <div class="ai-response-container">
                            <a (click)="customerForm()" class="ai-box">
                                <div class="ai-meta-loader">
                                </div>
                                <p class="mb-0">We require riders to facilitate the delivery of our orders</p>
                                <span class="ai-click-here">
                                    Join Now
                                </span>
                            </a>
                            <a routerLink="/partner-dms" class="ai-box">
                                <div class="ai-meta-loader">

                                </div>
                                <p class="mb-0">We have a team of riders available and are seeking orders</p>
                                <span class="ai-click-here">
                                    Join Now
                                </span>
                            </a>
                            <a routerLink="/partner-dms" class="ai-box">
                                <div class="ai-meta-loader">

                                </div>
                                <p class="mb-0">We have orders and riders, and are interested in using DMS</p>
                                <span class="ai-click-here">
                                    Join Now
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
    <div class="container-fluid partner-container">
        <app-partner-slider></app-partner-slider>
    </div>   
</div>
<div class="text-center">
    <p class="mb-0 mt-2">Loyalists is how we describe our 200+ Customers</p>
</div>

<app-threepl-section></app-threepl-section>

<!-- <app-story-section></app-story-section> -->

<app-animation-data-section></app-animation-data-section>
<div class="success-section">
    <app-successfully-delivered></app-successfully-delivered>
</div>
<app-feedback-style-two></app-feedback-style-two>

<!-- <app-funfacts-feedback></app-funfacts-feedback> -->

<!-- <app-instructor-quote></app-instructor-quote> -->

<!-- <app-features></app-features> -->

<!-- <app-oa-courses></app-oa-courses> -->

<app-blog></app-blog>
<app-subscribe></app-subscribe>

