import { Component } from '@angular/core';

@Component({
  selector: 'app-what-is-food-delivery-software',
  templateUrl: './what-is-food-delivery-software.component.html',
  styleUrl: './what-is-food-delivery-software.component.scss'
})
export class WhatIsFoodDeliverySoftwareComponent {

}
