<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Business</li>
            </ul>
            <h2>Deliver on your brand with Adloggs <br class="ai-break"/>Delivery Software</h2>
        </div>
    </div>
</div>

<!-- <div class="banner-container">
    <img src="../assets/img/banners/business.jpg" alt="bannerImage">
</div> -->

<div class="about-area-two pb-70" id="Customer">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <h2>Deliver with Adloggs (Customer) </h2>
                    <p>We offer a single-window delivery system that aggregates all available delivery options in the market. Whether you have 2 orders a month or 2000 orders a day, we treat every customer with the same respect. Easily find reliable riders, simplify your logistics, and ensure timely deliveries. Build your order volume with us and enhance your operational efficiency and customer service.</p>
                    <a (click)="customerForm()" class="default-btn cursor-pointer">Deliver with Adloggs<span></span></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div><img src="assets/img/business/customer.png" alt="customer"></div>
            </div>
        </div>
    </div>
</div>

<div class="about-area-two ad-customers" id="Partner">
    <div class="container">
        <div class="row align-items-center">            
            <div class="col-lg-7 col-md-12">
                <div><img src="assets/img/business/partner.png" alt="partner"></div>
            </div>
            <div class="col-lg-5 col-md-12 order-first order-md-1">
                <div class="about-content-box">
                    <h2>Partner with Adloggs (Partner)</h2>
                    <p>We invite delivery companies across India to partner with Adloggs and deliver for multiple brands. No need to hunt for demand; we ensure a steady flow of orders. Your focus? Timely deliveries. This partnership allows you to maximize your fleet’s potential effortlessly. Join us at Adloggs and experience the perfect synergy between demand and delivery, creating a match made in heaven.</p>
                    <a routerLink="/partner-dms" class="default-btn">Maximize your Potential<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area-two pt-70 pb-70" id="DMS">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <h2>Delivery Management System (DMS)</h2>
                    <p>Adloggs offers a free, super-secure, and certified Delivery Management System (DMS) that’s so good, you'll think it’s magic. It includes everything from real-time tracking to rider management, wages, and more. White-labelled for your brand, it's like having your own DMS genie. The catch? Delivery companies need to do a few deliveries for us, and businesses must deliver with us. Simple, right? Try it—your riders will thank you!</p>
                    <a routerLink="/partner-dms" class="default-btn">Unlock Your DMS Genie<span></span></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div><img src="assets/img/business/dms.png" alt="DMS"></div>
            </div>
        </div>
    </div>
</div>

<!-- faq -->
<app-faq-components></app-faq-components>

<app-subscribe></app-subscribe>

