<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Terms and Conditions</li>
            </ul>
            <h2>Adloggs Technologies Private Limited Terms and Conditions</h2>
        </div>
    </div>
</div>

<div class="terms-of-service-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="terms-of-service-content">
                    <h3>Introduction</h3>
                    <p>Welcome to Adloggs, a Delivery Management Software ("the Platform") distributed by Adloggs Technologies Private Limited ("we", "us", "our"). The Platform is designed to assist businesses such as Food Outlets, Grocery Stores, Pharmacies, Restaurants, etc., in onboarding their delivery staff, setting up their profiles, tracking their performance, and sending live tracking information to manage their delivery operations efficiently.</p>                    
                    <p>By signing up for and using the Adloggs software, you ("the User", "you") agree to be bound by these Terms and Conditions ("Terms"). Please read these Terms carefully before accessing or using our services.</p>
                    <h3>1. Software Use and Services</h3>
                    <p><strong>1.1 Delivery Orchestration System:</strong> Adloggs includes a Delivery Orchestration System that connects Delivery Drivers from third-party Delivery Companies to our Users. Users can push orders to Adloggs, which are then matched with drivers from multiple delivery companies. Users are charged for each delivery facilitated through Adloggs.</p>
                    <p><strong>1.2 Principal to Principal Basis:</strong> We partner with delivery companies on a principal to principal basis. We act solely as a software intermediary that facilitates the placement of orders via an API. We do not engage in or guarantee the delivery of items nor assume responsibility for the delivery process or any mishaps that may occur during delivery.</p>
                    <p><strong>1.3 Exclusions of Liability:</strong> We are not responsible for any issues that may arise before, during, or after a delivery, including but not limited to:</p>
                    <p>Misconduct or inappropriate behavior by delivery riders;</p>
                    <p>Damages to or loss of the package during transit;</p>
                    <p>Failure of the delivery rider to successfully deliver the package due to customer unavailability.</p>
                    <p>In cases where the customer is unavailable to receive the order upon arrival (after two attempted calls), the package will be returned to the originating store, and a charge will apply for the return.</p>
                    <p><strong>1.4 Quality and Package Responsibility:</strong> Adloggs does not take responsibility for the quality of the products being delivered or the condition of the package once it leaves the origin point. The responsibility for the product's quality and packaging integrity lies solely with the User and/or the originating business.</p>
                    <h3>2. Availability and Uptime</h3>
                    <p>2.1 We commit to maintaining the Platform's uptime at 99.5%. In the event of any failures, we will communicate the issue and rectify it as soon as possible, in accordance with the support and resolution packages outlined in these Terms.</p>
                    <h3>3. Issue Resolution and Support</h3>
                    <p>3.1 Adloggs provides a structured issue resolution package for operational efficiency, detailed as follows:</p>
                    <p><strong>Shutdown (P1):</strong> Immediate response to platform shutdown issues within 4 hours on an 8*5 basis (Monday to Friday, Business hours), with a specific escalation matrix.</p>
                    <p><strong>Bugs (P2):</strong> Responses to non-critical bugs and issues within 8 hours, supported on an 8*5 basis, with specified escalation procedures.</p>
                    <p><strong>Improvements (P3):</strong> UI changes and minor improvements with a response time of 16 hours, supported on an 8*5 basis, following a defined escalation process.</p>
                    <p>3.2 To ensure effective resolution and communication, Users are required to raise a JIRA ticket for every query and maintain documentation of all communications and commitments on the relevant JIRA ticket.</p>
                    <h3>4. Acceptance of Terms</h3>
                    <p>By using the Adloggs Platform, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you do not agree with any part of these Terms, you must not use the Platform.</p>
                    <h3>5. Amendments</h3>
                    <p>We reserve the right to modify these Terms at any time. Your continued use of the Platform after any such changes constitutes your acceptance of the new Terms.</p>
                    <h3>6. Contact Us</h3>
                    <p>For any questions or concerns regarding these Terms, please contact us through the designated channels provided on our website or <a href="mailto:darshan@adloggs.com">darshan&#64;adloggs.com</a></p>
                    <h3>7. Payments and Wallet</h3>
                    <p><strong>7.1 Prepayments and Wallet Use:</strong> Users may opt to make prepayments into a digital wallet provided by Adloggs, which will be used to deduct charges for the services as they are availed. By choosing to deposit funds into the wallet, Users acknowledge and agree that these funds are non-refundable under any circumstances.</p>
                    <p><strong>7.2 GST and Wallet Deductions:</strong> Goods and Services Tax (GST) is applicable and collected at the time of each deposit into the wallet. Deductions from the wallet for services availed will include the necessary GST amounts, in accordance with Indian tax regulations.</p>
                    <p><strong>7.3 No Credit Card Information Storage:</strong> Adloggs does not store any credit card information. All financial transactions and payment details are processed through a third-party payment gateway, Cashfree Payments, which is a reputed payment gateway based in India.</p>
                    <p><strong>7.4 Payment Gateway Issues:</strong> Any issues or concerns related to payment transactions, including but not limited to payment failures, refunds, or discrepancies, should be directed to the Cashfree Support team. Adloggs assumes no responsibility for issues arising from payment processing and directs Users to resolve such matters directly with Cashfree Payments.</p>
                    <h3>8. General Provisions</h3>
                    <p><strong>8.1 Modification of Terms:</strong> Adloggs Technologies Private Limited reserves the right to modify these Terms and Conditions at any time. Any modifications will be effective immediately upon posting the updated Terms on the Adloggs website or by direct communication to Users. By continuing to use the Platform after such modifications, Users agree to be bound by the revised Terms.</p>
                    <p><strong>8.2 Contact and Communications:</strong> For any queries or support related to these Terms, the Adloggs Platform, or services offered, Users should contact Adloggs Technologies Private Limited through the official communication channels provided on our website.</p>
                    <h3>Conclusion</h3>
                    <p>These Terms and Conditions constitute the entire agreement between Adloggs Technologies Private Limited and its Users regarding the use of the Adloggs Platform. By accessing or using the Platform, Users signify their acceptance of these Terms. If you disagree with any part of these Terms, please refrain from using our services.</p>
                </div>
            </div>
        </div>
    </div>
</div>
