import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-partner-dms',
  templateUrl: './partner-dms.component.html',
  styleUrls: ['./partner-dms.component.scss'] // Note the correction to `styleUrls`
})
export class PartnerDmsComponent {
  isChecked = false;

  @ViewChild('webform4164692000001256003') form!: ElementRef<HTMLFormElement>;
  captchaSrc: string;
  constructor(private toaster: ToastrService,private router: Router,) {
    this.captchaSrc = this.getCaptchaSrc();
}
getCaptchaSrc(): string {
  const baseSrc = 'https://crm.zoho.com/crm/CaptchaServlet?formId=9c6c020ca886978ec9088e64ac6bdec392407cbe56f382731fd276411190bcba7736a930c67e18bf789abd1632593673&grpid=edd8039e3ee2d076fba0a87f7a1ab101beaeceb0f15c38b600c3174c4d1995ee';
  return baseSrc + '&d=' + new Date().getTime();
}

reloadCaptcha(): void {
  this.captchaSrc = this.getCaptchaSrc();
}
  onCheckboxChange(event: any) {
    this.isChecked = event.target.checked;
  }
  // ngAfterViewInit() {
  //   console.log('Form reference:', this.form);
  // }

  historyBack4164692000001256003(): void {
    const submitButton = document.querySelector('.crmWebToEntityForm .formsubmit') as HTMLButtonElement;
    submitButton.removeAttribute('disabled');
    this.reloadCaptcha();
    window.removeEventListener('focus', this.historyBack4164692000001256003);
  }

  validateEmail4164692000001256003(): boolean {
    const form = document.forms['WebToLeads4164692000001256003'] as HTMLFormElement;
    const emailFld = form.querySelectorAll('[ftype=email]') as NodeListOf<HTMLInputElement>;

    for (let i = 0; i < emailFld.length; i++) {
      const emailVal = emailFld[i].value.trim();
      if (emailVal.length !== 0) {
        const atpos = emailVal.indexOf('@');
        const dotpos = emailVal.lastIndexOf('.');
        if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
          this.toaster.error('Please enter a valid email address.')
          emailFld[i].focus();
          return false;
        }
      }
    }
    return true;
  }
  onSubmit(): void {
    if (this.checkMandatory4164692000001256003()) {
      // Submits the form to Zoho CRM
      if (this.form) {
        this.form.nativeElement.submit();
        // setTimeout(() => {
        //   this.router.navigate(['/partner-dms'])
        //   window.addEventListener('focus', this.historyBack4164692000001256003.bind(this));
        // },2000)
      }else {
        console.error('Form is not available.');
      }

    }
  }
  checkMandatory4164692000001256003(): boolean {
    const mndFileds = ['Company', 'Last Name', 'Email', 'LEADCF2'];
    const fldLangVal = ['Company', 'Primary\x20Contact\x20First\x20Name', 'Email', 'GST\x20No'];

    for (let i = 0; i < mndFileds.length; i++) {
      const fieldObj = document.forms['WebToLeads4164692000001256003'][mndFileds[i]] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
      if (fieldObj) {
        if (fieldObj.value.trim().length === 0) {
          if (fieldObj.type === 'file') {
            this.toaster.error('Please select a file to upload.');
            fieldObj.focus();
            return false;
          }
          this.toaster.error(`${fldLangVal[i]} cannot be empty.`);
          fieldObj.focus();
          return false;
        } else if (fieldObj.nodeName === 'SELECT') {
          if ((fieldObj as HTMLSelectElement).options[(fieldObj as HTMLSelectElement).selectedIndex].value === '-None-') {
            this.toaster.error(`${fldLangVal[i]} cannot be none.`);
            fieldObj.focus();
            return false;
          }
        } else if (fieldObj.type === 'checkbox') {
          if (!(fieldObj as HTMLInputElement).checked) {
            this.toaster.error(`Please accept ${fldLangVal[i]}`);
            fieldObj.focus();
            return false;
          }
        }
        try {
          if (fieldObj.name === 'Last Name') {
            const name = fieldObj.value;
          }
        } catch (e) {}
      }
    }
    if (!this.validateFileUpload4164692000001256003()) {
      return false;
    }
    if (!this.validateEmail4164692000001256003()) {
      return false;
    }
    const urlparams = new URLSearchParams(window.location.search);
    if (urlparams.has('service') && urlparams.get('service') === 'smarturl') {
      const webform = document.getElementById('webform4164692000001256003') as HTMLFormElement;
      const service = urlparams.get('service')!;
      const smarturlfield = document.createElement('input');
      smarturlfield.setAttribute('type', 'hidden');
      smarturlfield.setAttribute('value', service);
      smarturlfield.setAttribute('name', 'service');
      webform.appendChild(smarturlfield);
    }
    // const submitButton = document.querySelector('.crmWebToEntityForm .formsubmit') as HTMLButtonElement;
    // submitButton.setAttribute('disabled', 'true');
    return true;
  }

  validateFileUpload4164692000001256003(): boolean {
    const uploadedFiles = document.getElementById('theFile4164692000001256003') as HTMLInputElement;
    let totalFileSize = 0;

    if (uploadedFiles.files!.length > 3) {
      this.toaster.error('You can upload a maximum of three files at a time.');
      return false;
    }
    if ('files' in uploadedFiles) {
      if (uploadedFiles.files!.length !== 0) {
        for (let i = 0; i < uploadedFiles.files!.length; i++) {
          const file = uploadedFiles.files![i];
          if ('size' in file) {
            totalFileSize += file.size;
          }
        }
        if (totalFileSize > 20971520) { // 20MB
          this.toaster.error('Total file(s) size should not exceed 20MB.');
          return false;
        }
      }
    }
    return true;
  }

  tooltipShow4164692000001256003(el: HTMLElement): void {
    const tooltip = el.nextElementSibling as HTMLElement;
    const tooltipDisplay = tooltip.style.display;

    if (tooltipDisplay === 'none') {
      const allTooltip = document.getElementsByClassName('zcwf_tooltip_over') as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < allTooltip.length; i++) {
        allTooltip[i].style.display = 'none';
      }
      tooltip.style.display = 'block';
    } else {
      tooltip.style.display = 'none';
    }
  }
}
