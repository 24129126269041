import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-feedback-style-two',
    templateUrl: './feedback-style-two.component.html',
    styleUrls: ['./feedback-style-two.component.scss']
})
export class FeedbackStyleTwoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    feedbackSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>"
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 2,
            }
        }
    }

    feedbacks = [
        {
          text: `I am incredibly grateful to Adloggs for their unwavering support in helping me emerge as a leader in my respective market. Their expertise, innovative strategies, and dedication to my growth have been truly transformative. From the very beginning, they took the time to understand my vision, tailored solutions to meet my unique needs, and provided invaluable guidance every step of the way. Their commitment to excellence, coupled with their industry knowledge...`,
          fullText: `I am incredibly grateful to Adloggs for their unwavering support in helping me emerge as a leader in my respective market. Their expertise, innovative strategies, and dedication to my growth have been truly transformative. From the very beginning, they took the time to understand my vision, tailored solutions to meet my unique needs, and provided invaluable guidance every step of the way. Their commitment to excellence, coupled with their industry knowledge, has not only elevated my brand but also empowered me with the tools and confidence to lead in a competitive landscape. Whether it was through strategic advice, cutting-edge technology, or personalized support, #Adloggs consistently exceeded my expectations. Thanks to their partnership, I’ve been able to achieve milestones that once seemed out of reach and confidently position myself as a leader in my field. I highly recommend Adloggs to any business or individual looking to unlock their full potential and make a lasting impact in their industry. They are truly the best at what they do.`,
          isExpanded: false, // Track if text is expanded
          name: 'Mr Bimlesh', // Dynamic name
          title: 'Founder of ', // Dynamic title
          company: 'Layaji', // Dynamic company
          imagePath: 'assets/img/testimonial/bimlesh.JPG', // Dynamic image path
          link: 'https://www.layaji.in/' // Dynamic link for the title
        },
        {
          text: `We RETOS-Hyperlocal Delivery a 3PL and local delivery agency, partnering with Adloggs in Vizag, Vijayawada, and Hyderabad. We're thrilled to be a key part of #Adloggs' backbone, delivering excellence in hyperlocal delivery! We are rocking with #Adloggs support in our region and expanding our operation across #Ap & #Telangana Thanks to @darshan-krishnasamy and @gautam-prakash for supporting us to grow as Leading Hyperlocal Agency in our region.`,
          fullText: `We RETOS-Hyperlocal Delivery a 3PL and local delivery agency, partnering with Adloggs in Vizag, Vijayawada, and Hyderabad. We're thrilled to be a key part of #Adloggs' backbone, delivering excellence in hyperlocal delivery! We are rocking with #Adloggs support in our region and expanding our operation across #Ap & #Telangana Thanks to @darshan-krishnasamy and @gautam-prakash for supporting us to grow as Leading Hyperlocal Agency in our region.`,
          isExpanded: false,
          name: 'Jagan dvn',
          title: 'Founder and CEO of ',
          company: 'RETOS', // Dynamic company
          imagePath: 'assets/img/testimonial/jagan-dvn.JPG',
          link: 'https://retosmarket.com/'
        }
      ];
    
        // Toggle expand/collapse for the selected feedback
        toggleExpand(feedback: any) {
            feedback.isExpanded = !feedback.isExpanded;
        }

        // Check if the text is more than 500 characters
        shouldShowReadMore(feedback: any): boolean {
            return feedback.fullText.length > 700;
        }

}