<div
    class="funfacts-and-feedback-area ptb-100"
    *ngFor="let Image of bgImage"
    style="background-image: url({{ Image.img }});"
    [ngClass]="{ 'd-none': router.url === '/online-art' }"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>
                        Flexible Study at Your Own Pace, According to Your Own
                        Needs
                    </h2>
                    <p>
                        With the adloggs, you can study whenever and wherever
                        you choose. We have students in over 175 countries and a
                        global reputation as a pioneer in the field of flexible
                        learning. Our teaching also means, if you travel often
                        or need to relocate, you can continue to study wherever
                        you go.
                    </p>
                    <div class="feedback-slides">
                        <owl-carousel-o [options]="feedbackSlides">
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua. Quis ipsum suspendisse ultrices
                                        gravida. Risus commodo viverra maecenas
                                        accumsan lacus vel facilisis. Lorem
                                        ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.
                                    </p>
                                    <div
                                        class="client-info d-flex align-items-center"
                                    >
                                        <img
                                            src="assets/img/user1.jpg"
                                            class="rounded-circle"
                                            alt="image"
                                        />
                                        <div class="title">
                                            <h3>John Smith</h3>
                                            <span>Python Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua. Quis ipsum suspendisse ultrices
                                        gravida. Risus commodo viverra maecenas
                                        accumsan lacus vel facilisis. Lorem
                                        ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.
                                    </p>
                                    <div
                                        class="client-info d-flex align-items-center"
                                    >
                                        <img
                                            src="assets/img/user2.jpg"
                                            class="rounded-circle"
                                            alt="image"
                                        />
                                        <div class="title">
                                            <h3>Sarah Taylor</h3>
                                            <span>PHP Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua. Quis ipsum suspendisse ultrices
                                        gravida. Risus commodo viverra maecenas
                                        accumsan lacus vel facilisis. Lorem
                                        ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.
                                    </p>
                                    <div
                                        class="client-info d-flex align-items-center"
                                    >
                                        <img
                                            src="assets/img/user1.jpg"
                                            class="rounded-circle"
                                            alt="image"
                                        />
                                        <div class="title">
                                            <h3>David Warner</h3>
                                            <span>QA Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="feedback-info">
                        <p>
                            Not a member yet?​
                            <a routerLink="/profile-authentication"
                                >Register now</a
                            >
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="1926">00</span></h3>
                                <p>Finished Sessions</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="3279">00</span></h3>
                                <p>Enrolled Learners</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="250">00</span></h3>
                                <p>Online Instructors</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="100">00</span>%</h3>
                                <p>Satisfaction Rate</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-video></app-video>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image" /></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image" /></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<!-- This section for Online Art Demo -->
<div
    class="oa-funfacts-and-feedback-area ptb-100 d-none"
    [ngClass]="{ 'd-block': router.url === '/online-art' }"
>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                <div class="oa-funfacts-box">
                    <h3><span [countUp]="3279">00</span></h3>
                    <p>ENROLLED LEARNERS</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                <div class="oa-funfacts-box">
                    <h3><span [countUp]="250">00</span></h3>
                    <p>ONLINE INSTRUCTORS</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                <div class="oa-funfacts-box">
                    <h3><span [countUp]="1926">00</span></h3>
                    <p>FINISHED SESIONS</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                <div class="oa-funfacts-box">
                    <h3><span [countUp]="100">00</span>%</h3>
                    <p>SATISFACTION RATE</p>
                </div>
            </div>
        </div>
        <div
            class="row justify-content-center align-items-center oa-feedback-area"
        >
            <div class="col-lg-7 col-md-12">
                <div class="oa-feedback-slides">
                    <owl-carousel-o [options]="oaFeedbackSlides">
                        <ng-template carouselSlide>
                            <div class="oa-single-feedback-item">
                                <div
                                    class="client-info d-flex align-items-center"
                                >
                                    <img
                                        src="assets/img/user/user1.jpg"
                                        class="rounded-circle"
                                        alt="image"
                                    />
                                    <div class="title">
                                        <h3>John Smith</h3>
                                        <span>Python Developer</span>
                                    </div>
                                </div>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipiscing elit sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua
                                    quis ipsum suspendisse ultrices gravida
                                    risus commodo viverra maecenas accumsan
                                    lacus vel facilisis lorem ipsum dolor sit
                                    amet
                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="oa-single-feedback-item">
                                <div
                                    class="client-info d-flex align-items-center"
                                >
                                    <img
                                        src="assets/img/user/user2.jpg"
                                        class="rounded-circle"
                                        alt="image"
                                    />
                                    <div class="title">
                                        <h3>James Andy</h3>
                                        <span>Project Management Expert</span>
                                    </div>
                                </div>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipiscing elit sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua
                                    quis ipsum suspendisse ultrices gravida
                                    risus commodo viverra maecenas accumsan
                                    lacus vel facilisis lorem ipsum dolor sit
                                    amet
                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="oa-single-feedback-item">
                                <div
                                    class="client-info d-flex align-items-center"
                                >
                                    <img
                                        src="assets/img/user/user3.jpg"
                                        class="rounded-circle"
                                        alt="image"
                                    />
                                    <div class="title">
                                        <h3>Lina Ninja</h3>
                                        <span>Illustrator Expert</span>
                                    </div>
                                </div>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipiscing elit sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua
                                    quis ipsum suspendisse ultrices gravida
                                    risus commodo viverra maecenas accumsan
                                    lacus vel facilisis lorem ipsum dolor sit
                                    amet
                                </p>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="oa-feedback-image">
                    <img src="assets/img/online-art/feedback.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
    <div class="oa-funfacts-and-feedback-arrow-shape">
        <img src="assets/img/online-art/arrow-shape.png" alt="image" />
    </div>
</div>
