import { Component } from '@angular/core';

@Component({
  selector: 'app-what-is-delivery-orchestration-system',
  // standalone: true,
  // imports: [],
  templateUrl: './what-is-delivery-orchestration-system.component.html',
  styleUrl: './what-is-delivery-orchestration-system.component.scss'
})
export class WhatIsDeliveryOrchestrationSystemComponent {

}
