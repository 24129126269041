<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Company</li>
            </ul>
            <h2>Adloggs is an delivery aggregation <br class="ai-break"> service platform</h2>
        </div>
    </div>
</div>
<div class="banner-container">
    <img src="assets/img/banners/business.jpg" alt="bannerImage">
</div>
<div class="about-area-two pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="text-left ai-title">
                    <h2 class="mb-4">Our Journey </h2>
                </div>  
                <div class="about-main">
                    <!-- <div class="about-container">
                        <div class="year-container">
                            <p class="mb-0">2012</p>
                        </div>
                        <div class="about-text-box">
                            <h2>Journey began in 2012 with Anything Delivered</h2>
                            <p>Concept was well received but, it was very ops heavy</p>
                        </div>
                    </div>
    
                    <div class="about-container">
                        <div class="year-container">
                            <p class="mb-0">2014</p>
                        </div>
                        <div class="about-text-box">
                            <h2>Closed the ordering business in 2014</h2>
                        </div>
                    </div>
    
                    <div class="about-container">
                        <div class="year-container">
                            <p class="mb-0">2017</p>
                        </div>
                        <div class="about-text-box">
                            <h2>Continued as a Hyperlocal uberized delivery system till 2017</h2>
                            <p>We continuously integrate with new delivery providers in your area and remove the dormant service providers therefore, keeping the platform live and upto date</p>
                        </div>
                    </div>
    
                    <div class="about-container">
                        <div class="year-container">
                            <p class="mb-0">2018</p>
                        </div>
                        <div class="about-text-box">
                            <h2>Launched Adloggs as a concept (no tech) in Singapore in 2018</h2>
                        </div>
                    </div> -->

                    <div class="about-container">
                        <div class="year-container">
                            <p class="mb-0">2021</p>
                        </div>
                        <div class="about-text-box">
                            <h2>Adloggs’ journey commences</h2>
                            <p>The crazy mission of bringing all hyperlocal logistics under one roof began.</p>
                        </div>
                    </div>

                    <div class="about-container">
                        <div class="year-container">
                            <p class="mb-0">2022</p>
                        </div>
                        <div class="about-text-box">
                            <h2>The first version of Adloggs Delivery Orchestration Platform is rolled out with 2 3PL partners.</h2>
                            <p class="history-dash mt-3"><strong>First international integration:</strong> Intergrated Adloggs with Doordash Australia</p>
                        </div>
                    </div>

                    <div class="about-container">
                        <div class="year-container">
                            <p class="mb-0">2023</p>
                        </div>
                        <div class="about-text-box">
                            <h2>Adloggs’ agencies start operations in Dehradun and Bhopal.</h2>
                            <p class="history-dash mt-3">Partnered with our first QSR Client KFC and changed the way their deliveries operated for good.</p>
                        </div>
                    </div>
    
                    <div class="about-container">
                        <div class="year-container">
                            <p class="mb-0">2024</p>
                        </div>
                        <div class="about-text-box">
                            <h2>Adloggs raises close to $1M in Seed funding from Multiply Ventures, Auxano Capital and others.</h2>
                            <p class="history-dash mt-3">2024 The most advanced DOS platform in India is live on ONDC and has partnered with QSRs like KFC, Pizza Hut, Taco bell, etc...</p>
                            <p class="history-dash mt-3">Signed up Strategic Alliance with Urban Piper to support their clients with DMS and DOS </p>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="text-end ad-about-img">
                    <img src="assets/img/aboutAdloggs.png" alt="aboutAdloggs">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container">
    <div class="success-container">
        <div class="row">
            <div class="col-12"> 
                <div class="ad-text-area">
                    <h2 class="mb-4">Highlights</h2>
                    <h5 class="mb-0 ad-about">Adloggs is a Delivery Infrastructure Platform that allows any business to Assign-Track-Build-Operate their deliveries. Founded and incorporated in 2021. Grown <strong>4X</strong> since 2022 as a bootstrapped company. Profitable since December 2022. <strong>EBITDA margins of 5%</strong>. Contracts in place to scale to 100X within 18 months. Integrated with ALL delivery agencies pan-India. Driven by a team with over 12 years experience in hyperlocal space</h5>
                </div>         
            </div>            
        </div>
    </div>
</div> -->

<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="success-container">
                <div class="ad-success-inner">
                    <h1 class="mb-4">Highlights</h1>
                    <p>Adloggs is a Delivery Infrastructure Platform that allows any business to Assign-Track-Build-Operate their deliveries. Founded and incorporated in 2021. Grown <strong>4X</strong> since 2022 as a bootstrapped company. Profitable since December 2022. <strong>EBITDA margins of 5%</strong>. Contracts in place to scale to 100X within 18 months. Integrated with ALL delivery agencies pan-India. Driven by a team with over 12 years experience in hyperlocal space.</p>
                </div>
                <img class="order-img" src="assets/img/about-adloggs.png" alt="AboutAdloggs">
            </div>            
        </div>
    </div>
</div>

<div class="success-section">
    <app-feedback-style-two></app-feedback-style-two>
</div>


<!-- <div class="success-section mt-70">
    <app-successfully-delivered></app-successfully-delivered>
</div> -->

<!-- <div class="my-70">
    <app-blog></app-blog>
</div> -->