<!-- adloggs version 0.1 -->
<div class="online-art-banner-area" style="background-image: url(assets/img/adBannerBg.svg);">
    <div class="online-art-banner-content">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12 col-sm-12">
                    <div class="ad-banner-wrapper-main">
                        <h5>#Delivery Simplified</h5>
                        <h1>AI Powered <br class="ai-break" /> Delivery Platform</h1>                        
                        <!-- <div class="banner-btn">
                            <a routerLink="/" class="default-btn"><i class="flaticon-user"></i>Partner With Us<span></span></a>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-7 col-md-12 col-sm-12">
                    <div class="ad-home-banner">
                        <object data="assets/img/ad-angular-banner.svg" alt="media" class="ad-animation-container"></object>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="ai-container mb-2">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="ai-form-container">
                        <div class="ai-form-wrapper">
                            <div class="ai-hi-text">
                                <img src="assets/img/icons/hi-icon.png" alt="ad-hi-icon">
                            </div>
                            <h3 class="mb-0">Hi, Are you here for?</h3>
                            <div class="ai-wrapper">
                                <div class="ai-icon">
                                    <img src="assets/img/icons/ai-icon.svg" alt="ad-ai-icon">
                                </div>
                                <div class="ai-loader-animation"></div>
                            </div>
                        </div>
                        <div class="ai-response-container">
                            <a routerLink="/customer/588" class="ai-box">
                                <div class="ai-meta-loader">
                                </div>
                                <p class="mb-0">We require riders to facilitate the delivery of our orders</p>
                            </a>
                            <a routerLink="/partner-dms" class="ai-box">
                                <div class="ai-meta-loader">

                                </div>
                                <p class="mb-0">We have a team of riders available and are seeking orders</p>
                            </a>
                            <a routerLink="/partner-dms" class="ai-box">
                                <div class="ai-meta-loader">

                                </div>
                                <p class="mb-0">We have orders and riders, and are interested in using your DMS</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>
<!-- <div class="online-art-banner-area" style="background-image: url(assets/img/online-art/banner/banner-bg.jpg);">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <div class="online-art-banner-image">
                    <img src="assets/img/online-art/banner/main1.png" alt="banner-image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="online-art-banner-content">
                    <h5>#Delivery Simplified</h5>
                    <h1>AI Powered Delivery Platform</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse.</p>
                    <div class="banner-btn">
                        <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>Find Courses<span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="online-art-banner-wrap-image">
                    <img src="assets/img/online-art/banner/main2.jpg" alt="banner-image">
                </div>
            </div>
        </div>
    </div>
    <div class="online-art-shape-1">
        <img src="assets/img/online-art/banner/shape.png" alt="shape">
    </div>
</div> -->