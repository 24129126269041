<!-- adloggs version 0.1 -->
<div class="banner-wrapper-area custom-margin-bottom">
    <div class="container">
        <div class="banner-inner-area">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-6 col-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon img-icon">
                            <img src="assets/img/icons/delivered.svg" alt="delivered">
                        </div>
                        <h3 class="mb-0">
                            <strong>≈5M +</strong>
                        </h3>
                        <p>Successfully Delivered</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon img-icon">
                            <img src="assets/img/icons/shop.svg" alt="shop">
                        </div>
                        <h3 class="mb-0">
                            <strong>≈9L +</strong>
                        </h3>
                        <p>Delivery Riders</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon img-icon">
                            <img src="assets/img/icons/clients.svg" alt="clients">
                        </div>
                        <h3 class="mb-0">
                            <strong>≈150 +</strong>
                        </h3>
                        <p>Agencies</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon img-icon">
                            <img src="assets/img/icons/customer.svg" alt="customer">
                        </div>
                        <h3 class="mb-0">
                            <strong>10K +</strong>
                        </h3>
                        <p>Customers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="ad-animation">
    <object data="assets/img/adloggs-slider.svg" alt="media" class="ad-animation-container"></object>
    <div class="ad-same-day-services">
        <div class="ad-video-container">
            <app-video></app-video>
        </div>
        <h2 class="mb-4 mt-4">Access all 3PL delivery <br class="ai-break"> companies in India through <br class="ai-break"> a single window</h2>
        <!-- <p>companies in India through a single window</p> -->
        <div class="banner-btn">
            <a (click)="customerForm()" class="default-btn cursor-pointer">Deliver with Adloggs<span></span></a>
        </div>
    </div>
</div>
<div class="banner-wrapper-area custom-margin-top">
    <div class="w-100">
        <div class="banner-inner-area">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-6 col-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon img-icon">
                            <img src="assets/img/icons/artificial-intelligence.svg" alt="artificial-intelligence">
                        </div>
                        <h3 class="mb-0">
                            <strong>95%</strong>
                        </h3>
                        <p><strong>AI Powered</strong> Delivery Platform</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon img-icon">
                            <img src="assets/img/icons/on-demand-delivery.svg" alt="on-demand-delivery">
                        </div>
                        <h3 class="mb-0">
                            <strong>96.2%</strong>
                        </h3>
                        <p><strong>On-time</strong> <br class="mobile-sm"> Deliveries</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon img-icon">
                            <img src="assets/img/icons/route.svg" alt="route">
                        </div>
                        <h3 class="mb-0">
                            <strong>98.5%</strong>
                        </h3>
                        <p><strong>Route Planning</strong> Optimization</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-6 single-line">
                    <div class="single-banner-box">
                        <div class="icon img-icon">
                            <img src="assets/img/icons/productivity.svg" alt="productivity">
                        </div>
                        <h3 class="mb-0">
                            <strong>52%</strong>
                        </h3>
                        <p><strong>Rider Productivity</strong> Enhancement</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
