<div class="featured-area">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="single-featured-box">
                    <img src="assets/img/dotted.png" alt="image">
                    <div class="content">
                        <i class="icon flaticon-computer"></i>
                        <h3>GO AT YOUR OWN PACE</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit, sed do eiusmod tempor.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="single-featured-box">
                    <img src="assets/img/dotted.png" alt="image">
                    <div class="content">
                        <i class="icon flaticon-shield-1"></i>
                        <h3>LEARN FROM EXPERT</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit, sed do eiusmod tempor.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="single-featured-box">
                    <img src="assets/img/dotted.png" alt="image">
                    <div class="content">
                        <i class="icon flaticon-world"></i>
                        <h3>PRACTICE FROM ANYEHERE</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit, sed do eiusmod tempor.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>