<div class="page-title-area">
    <div class="container">
       <div class="page-title-content">
          <ul>
             <li><a routerLink="/">Home</a></li>
             <li>Privacy Policy</li>
          </ul>
          <h2>Adloggs Technologies Private Limited Privacy Policy</h2>
       </div>
    </div>
 </div>
 <div class="terms-of-service-area pb-100">
    <div class="container">
       <div class="row">
          <div class="col-lg-8 col-md-12">
             <div class="terms-of-service-content">
                <h3>Introduction</h3>
                <p>Adloggs Technologies Private Limited ("Adloggs", "we", "us", "our") is committed to protecting the privacy of individuals ("Users", "you") who use our Delivery Management Software and Services ("the Platform"). This Privacy Policy outlines the types of information we may collect from you or that you may provide when you use the Platform and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
                <h4>1. Information Collection</h4>
                <h5>1.1 Information You Provide to Us: </h5>
                <p>
                   We collect information that you provide directly to us when you: <br>
                   - Register for an account on the Platform;<br>
                   - Fill out forms or input data on the Platform;<br>
                   - Communicate with us via email, phone, or other channels.<br>
                   This information may include your name, address, email address, phone number, and any other details you choose to provide.
                </p>
                <h5>1.2 Transactional Information:  </h5>
                <p>When you make transactions through the Platform, we collect information related to those transactions, which may include service usage details and payment history. However, we do not store credit card information, as all payment transactions are processed through our partner, Cashfree Payments.</p>
                <h5>1.3 Automatically Collected Information: </h5>
                <p>As you navigate through and interact with the Platform, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including details of your visits to the Platform, traffic data, location data, logs, and other communication data, and the resources that you access and use on the Platform.</p>
                <h5>1.4 Information from B2B Customers:</h5>
                <p>We may store some information that we obtain through our B2B customers. This information is used solely for the purpose of providing our services and is not sold or shared with third parties for marketing or other purposes.</p>
                <h4>2. Use of Information</h4>
                <p>
                   We use the information we collect about you or that you provide to us, including any personal information: <br>
                   - To present our Platform and its contents to you;<br>
                   - To provide you with information, products, or services that you request from us;<br>
                   - To fulfill any other purpose for which you provide it;<br>
                   - To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;<br>
                   - To notify you about changes to our Platform or any products or services we offer or provide through it;<br>
                   - For any other purpose with your consent.
                </p>
                <h4>3. Disclosure of Your Information</h4>
                <p>
                   We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. We may disclose personal information that we collect or you provide: <br>
                   - To our subsidiaries and affiliates;<br>
                   - To contractors, service providers, and other third parties we use to support our business;<br>
                   - To fulfill the purpose for which you provide it;<br>
                   - For any other purpose disclosed by us when you provide the information; With your consent.
                </p>
                <h4>4. Data Security</h4>
                <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. However, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Platform.</p>
                <h4>5. Changes to Our Privacy Policy</h4>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
                <h4>6. Contact Information</h4>
                <p>To ask questions or comment about this Privacy Policy and our privacy practices, contact us at <a class="priv-a" href="mailto:darshan@adloggs.com">darshan&#64;adloggs.com</a></p>

                <h4>For grievances please contact:</h4>
                <p>Mr. Vignesh Murugan <br> <a class="priv-a" href="tell:+919500303975 ">+91 95003 03975 </a> <br>
                <a class="priv-a" href="mailto:vignesh@adloggs.com">vignesh&#64;adloggs.com</a> 
                </p>
            </div>
          </div>
       </div>
    </div>
 </div>