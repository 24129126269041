import { Component, ElementRef, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgOtpInputConfig } from 'ng-otp-input';
import { ToastrService } from 'ngx-toastr';
import constant from 'src/app/app.constant';
import { CommonHttpService } from 'src/app/utils/common-http.service';
import commonLogics from 'src/app/utils/common-logic.service';
import { GoogleService } from 'src/app/utils/googleService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var google: any;
@Component({
  selector: 'app-forms',
  // standalone: true,
  // imports: [],
  templateUrl: './forms.component.html',
  styleUrl: './forms.component.scss'
})
export class FormsComponent {
  disableBtn = false;
  customerFormGroup: FormGroup;
  submitted = false;
  //map
  isHovered = false;
  zoom: number = 15;
  @ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;
  map: google.maps.Map;
  center: google.maps.LatLngLiteral;
  geocoder: google.maps.Geocoder;
  lat = 37.7749;
  lng = -122.4194;
  allowPermission: boolean = false;
  termsError: boolean = false;
  user_id: any;
  cityDataList: any;
  pageSwitch: boolean = true;
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };
  otp: string;
  timestamp: any;
  phoneNumber: any;
  otpError: boolean = false;
  otpMessage: string;
  otpSuccess: boolean = false;
  constructor(private router: Router, private modalService: NgbModal, private toaster: ToastrService, private fb: FormBuilder, private activatedRoute: ActivatedRoute, private http: CommonHttpService, private ngZone: NgZone, public googleService: GoogleService) {
    this.geocoder = new google.maps.Geocoder();
  }
  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async params => {
      this.user_id = params.get('id');
    });
    this.customerFormGroup = this.fb.group({
      first_name: ['', [Validators.required, Validators.maxLength(100)]],
      gst_no: ['', [Validators.required,Validators.maxLength(15),
      // Validators.pattern(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)
      Validators.pattern(/^[A-Z0-9]*$/)
      ]],
      phone: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), Validators.maxLength(90)]],
      address: ['', [Validators.required, Validators.maxLength(700)]],
      country_id: [null],
      state_id: [null],
      city_id: [null],
      district_id: [null],
      zip_code: [null],
      lat: [null],
      long: [null],
      google_address_dtl: [null, Validators.required],
      utc_offset: [commonLogics.getUtcOffset()],
      termsAndCondition: [false, Validators.required],
    })
  }
  ngAfterViewInit(): void {
    this.loadMap();
  }
  onCheckboxChange(event: any) {
    if (event.target.checked) {
      this.termsError = false;
    } else {
      this.termsError = true;
    }
  }
  getEmailCheck() {
    var email = this.customerFormGroup.get('email').value
    this.http.get(constant.Service.MainLogin.BaseUrl + constant.Service.MainLogin.User.emailCheck + '?email=' + email).subscribe((response: any) => {
      if (response.status == false) {
        this.toaster.error(response.message)
        this.customerFormGroup.controls['email'].setValue('');
      };
    });
  }
  onInputChange(event: Event) {
    const input = (event.target as HTMLInputElement).value.toUpperCase();
    this.customerFormGroup.get('gst_no')?.setValue(input, { emitEvent: false });
  }
  onInputChangeEmail(event: Event) {
    const input = (event.target as HTMLInputElement).value.toLowerCase();
    this.customerFormGroup.get('email')?.setValue(input, { emitEvent: false });
  }
  submit() {
    this.submitted = true;

    if (!this.customerFormGroup.value.termsAndCondition) {
      return this.termsError = true;
    }
    if (this.customerFormGroup.valid) {
      this.disableBtn = true;
      var reqJson: any = {
        ...this.customerFormGroup.value,
      }
      this.http.postData(constant.Service.MainLogin.BaseUrl + constant.Service.MainLogin.Vendor.Create + '/' + this.user_id, reqJson).subscribe((response: any) => {
        if (response.status) {
          this.toaster.success(response.message);
          // this.toaster.success("Account created. Please check your mail for credentials");
          this.timestamp = response?.data?.timestamp;
          this.phoneNumber = response?.data?.phone;
          this.openOtpModal();
          this.disableBtn = false;
        } else {
          this.disableBtn = false;
          this.toaster.error(response.message);
        }
      });
    }
  }
  onOtpChange(otp: any) {
    console.log(otp)
    this.otp = otp;
  }
  onSubmit() {

    if (this.otp && this.otp.length == 4) {
      this.otpError = false;
      const reqJson = {
        timestamp: this.timestamp,
        otp: Number(this.otp)
      }
      this.http.postData(constant.Service.MainLogin.BaseUrl + constant.Service.MainLogin.User.otpVerify, reqJson).subscribe((response: any) => {
        if (response.status) {
          this.otpSuccess = true;
          this.toaster.success("Account created. Please check your mail for credentials");
        } else {
          this.toaster.error(response.message);
        }
      });
    } else {
      this.otpError = true;
      if (this.otp.length) this.otpMessage = 'Please enter the correct OTP';
      else this.otpMessage = 'Please enter the OTP';
    }
  }
  doneFun() {
    this.modalService.dismissAll();
    this.router.navigate(['/'])
  }
  closeFun() {
    this.modalService.dismissAll();
  }

  @ViewChild("content") modalContent: TemplateRef<any>;
  openOtpModal() {
    this.modalService.open(this.modalContent, { centered: true, scrollable: true, size: 'xl' });
  }
  onSearchChange(event: any) {
    const inputAddress = document.querySelector("#ship-address") as HTMLInputElement;

    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.
    const autocomplete = new google.maps.places.Autocomplete(inputAddress, {
      // componentRestrictions: { country: ["us", "ca"] },
      // fields: ["address_components", "geometry"],
      // types: ["address"],
    });
    inputAddress.focus();

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(async () => {
        let place = autocomplete.getPlace();
        const placeDetails = await this.googleService.googleAddressLoad(place);
        this.customerFormGroup.controls['address'].setValue(placeDetails.formatted_address);
        this.customerFormGroup.controls['google_address_dtl'].setValue(placeDetails);

        // this.cityCheck(placeDetails);

        this.center = { lat: placeDetails.lat, lng: placeDetails.long }
        this.ngZone.run(() => {
          this.center = {
            lat: placeDetails.lat,
            lng: placeDetails.long
          };
          // console.log('Center set to:', this.center);
          this.updateMapCenter(this.center);
        });
        console.log(placeDetails)
      })
    })

  }


  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
  }
  handlePermission() {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        this.report(result.state);
        this.getUserLocation();
      } else if (result.state === "prompt") {
        this.report(result.state);
        this.getUserLocation();
      } else if (result.state === "denied") {
        this.report(result.state);
        console.log("denied")
      }
      result.addEventListener("change", () => {
        this.report(result.state);
      });
    });
  }
  report(state) {
    console.log(`Permission ${state}`);
    if (state == 'denied') {
      this.allowPermission = true;
    } else {
      this.allowPermission = false;
    }
  }
  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Position:', position.coords);
          this.ngZone.run(() => {
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            // console.log('Center set to:', this.center);
            this.updateMapCenter(this.center);
          });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    }
  }
  updateMapCenter(center: google.maps.LatLngLiteral) {
    this.map.setCenter(center);
    this.findAddressByCoordinates();
  }

  loadMap() {
    const mapProperties: google.maps.MapOptions = {
      center: new google.maps.LatLng(11.01684, 76.95583), // Valid latitude and longitude
      zoom: 15, // Ensure this is a valid zoom level (0 to 21+)
      mapTypeId: google.maps.MapTypeId.ROADMAP, // Must be a valid map type
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false
    };

    this.map = new google.maps.Map(this.mapContainer?.nativeElement, mapProperties);

    // Add event listeners for map changes
    // this.map.addListener('idle', () => {
    //   console.log('idle');

    //   this.ngZone.run(() => {
    //     this.center = {
    //       lat: this.map.getCenter().lat(),
    //       lng: this.map.getCenter().lng()
    //     };
    //     this.findAddressByCoordinates();
    //   });
    // });

    this.map.addListener('dragend', () => {
      console.log('dragend');
      this.ngZone.run(() => {
        this.center = {
          lat: this.map.getCenter().lat(),
          lng: this.map.getCenter().lng()
        };
        this.findAddressByCoordinates();
      });
    });
  }

  findAddressByCoordinates() {
    this.geocoder.geocode({
      'location': {
        lat: this.center.lat,
        lng: this.center.lng
      }
    }, (results, status) => {
      console.log(results)
      if (results && results.length > 0) {
        this.onAutocompleteSelected(results[0]);
      }
    });
  }

  onAutocompleteSelected(result: any) {
    if (result && result.address_components && result.address_components.length) {
      this.ngZone.run(async () => {
        let placeDetails = await this.googleService.googleAddressLoad(result);
        this.customerFormGroup.controls['google_address_dtl'].setValue(placeDetails);
        this.customerFormGroup.controls['address'].setValue(placeDetails.formatted_address);

        this.cityCheck(placeDetails);

      });
    }
  }

  cityCheck(placeDetails: any) {
    this.http.postData(constant.Service.MainLogin.BaseUrl + constant.Service.MainLogin.City.AddCity, placeDetails).subscribe((response: any) => {
      this.cityDataList = response.data;
      if (this.cityDataList.cityCreated) {
      } else {
      }
      if (response.status) {
        this.customerFormGroup.patchValue({
          lat: placeDetails?.lat,
          long: placeDetails?.long,
          city_id: this.cityDataList?.city_id,
          state_id: this.cityDataList?.state_id,
          district_id: this.cityDataList?.district_id,
          country_id: this.cityDataList?.country_id,
          address: placeDetails?.formatted_address,
          zip_code: placeDetails?.pincode,
        })
      } else {
        this.toaster.error(response.message)
        this.customerFormGroup.get('address').setValue('')
      }
    }, err => {
      console.error();
    });
  }

}
