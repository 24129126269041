<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img src="assets/img/error.png" alt="image">
                    <h3>404 - Page Not Found</h3>
                    <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                    <div class="btn-box">
                        <a routerLink="/" class="default-btn">Home page<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>