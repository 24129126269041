import { Component } from '@angular/core';

@Component({
  selector: 'app-story-section',
  // standalone: true,
  // imports: [],
  templateUrl: './story-section.component.html',
  styleUrl: './story-section.component.scss'
})
export class StorySectionComponent {

}
