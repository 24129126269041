import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogs',
  // standalone: true,
  // imports: [],
  templateUrl: './blogs.component.html',
  styleUrl: './blogs.component.scss'
})
export class BlogsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {}

  // Tabs
  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
      event.preventDefault();
      this.currentTab = tab;
  }
}
