import { Component } from '@angular/core';

@Component({
  selector: 'app-delivery-management-software',
  templateUrl: './delivery-management-software.component.html',
  styleUrl: './delivery-management-software.component.scss'
})
export class DeliveryManagementSoftwareComponent {

}
