<!--  adloggs version 0.1 -->
<!-- <div class="feedback-area ptb-100">
    <div class="container">
        <div class="about-content-box">
            <h2>Our Customers <br class="ai-break"/>Words are Gospel Truth</h2>
        </div>
        <div class="feedback-slides-two">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p class="ad-client-content">
                            I am incredibly grateful to <strong>Adloggs</strong> for their unwavering support in helping me emerge as a leader in my respective market. Their expertise, innovative strategies, and dedication to my growth have been truly transformative. From the very beginning, they took the time to understand my vision, tailored solutions to meet my unique needs, and provided invaluable guidance every step of the way.

                            Their commitment to excellence, coupled with their industry knowledge, has not only elevated my brand but also empowered me with the tools and confidence to lead in a competitive landscape. Whether it was through strategic advice, cutting-edge technology, or personalized support, <strong>Adloggs</strong> consistently exceeded my expectations. Thanks to their partnership, I’ve been able to achieve milestones that once seemed out of reach and confidently position myself as a leader in my field.

                            I highly recommend <strong>Adloggs</strong> to any business or individual looking to unlock their full potential and make a lasting impact in their industry. They are truly the best at what they do.
                        </p>
                        <div class="client-info d-flex align-items-center">
                            <img src="assets/img/testimonial/bimlesh.JPG" class="rounded-circle" alt="image">
                            <div class="title">
                                <h3>Mr Bimlesh</h3>
                                <span>Founder of <a href="https://www.layaji.in/" target="_blank">Layaji</a></span>
                            </div>
                        </div>
                    </div>
                </ng-template>      
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p class="ad-client-content">
                            We RETOS-Hyperlocal Delivery a 3PL and local delivery
                            agency, partnering with <strong>Adloggs</strong> in Vizag, Vijayawada,
                            and Hyderabad. We're thrilled to be a key part of
                            <strong>#Adloggs</strong>' backbone, delivering excellence in hyperlocal delivery!

                            We are rocking with <strong>#Adloggs</strong> support in our region
                            and expanding our operation across #Ap & #Telangana

                            Thanks to <a href="https://www.linkedin.com/in/darshan-krishnasamy-80039b7b/" target="_blank">&#64;darshan-krishnasamy</a> and <a href="https://www.linkedin.com/in/gautam-prakash-1a1506273/" target="_blank">&#64;gautam-prakash</a> for supporting us
                            to grow as Leading Hyperlocal Agency in our region.
                        </p>
                        <div class="client-info d-flex align-items-center">
                            <img src="assets/img/testimonial/jagan-dvn.JPG" class="rounded-circle" alt="image">
                            <div class="title">
                                <h3>Jagan dvn</h3>
                                <span>Founder of <a href="https://retosmarket.com/" target="_blank">'RETOS'</a></span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div> -->

<div class="feedback-area ptb-100">
    <div class="container">
      <div class="about-content-box">
        <h2>Our Customers <br class="ai-break" />Words are Gospel Truth</h2>
      </div>
      <div class="feedback-slides-two">
        <owl-carousel-o [options]="feedbackSlides">
          <ng-template carouselSlide *ngFor="let feedback of feedbacks">
            <div class="single-feedback-box">
              <!-- Display truncated or full content based on isExpanded -->
              <div class="d-block">
                <p class="ad-client-content">
                    <span>{{ feedback.isExpanded ? feedback.fullText : feedback.text }}</span> &nbsp;
                    <span class="ml-3">
                        <a *ngIf="shouldShowReadMore(feedback)" href="javascript:void(0);" (click)="toggleExpand(feedback)" class="ad-read-more-btn mt-0">
                            {{ feedback.isExpanded ? 'Read Less' : 'Read More' }}
                        </a>
                    </span>
                </p>
              </div>  
              <div class="client-info d-flex align-items-center">
                <!-- Dynamically set the image source -->
                <img [src]="feedback.imagePath" class="rounded-circle" alt="{{ feedback.name }}">  
                <div class="title">
                  <!-- Dynamically set the name and title -->
                  <h3>{{ feedback.name }}</h3>
                  <div class="d-flex">
                    <p class="mb-0 d-flex">
                        <span>{{ feedback.title }}</span> &nbsp;
                        <span class="ml-3"><a [href]="feedback.link" target="_blank">{{ feedback.company }}</a></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>