import { Injectable } from "@angular/core";
import constant from '../app.constant';


@Injectable({
    providedIn: 'root'
})
export class GoogleService {


    selectedAddressDetails: any = {}


    public async googleAddressLoad(place: any) {
        this.selectedAddressDetails = {};
        var componentForm = {
            subpremise: {
                long_name: 'sub_premise'
            },
            premise: {
                long_name: 'flat_number'
            },
            street_number: {
                long_name: 'street_number'
            },
            route: {
                long_name: 'street_name'
            },
            neighborhood: {
                long_name: 'neighborhood'
            },
            sublocality_level_2: {
                long_name: 'sublocality_level_2'
            },
            sublocality_level_1: {
                long_name: 'sublocality_level_1'
            },
            locality: {
                long_name: 'locality'
            },
            administrative_area_level_2: {
                long_name: 'district_name',
                short_name: 'district_short_name'
            },
            administrative_area_level_1: {
                long_name: 'state_name',
                short_name: 'state_short_name',
            },
            country: {
                long_name: 'country_name',
                short_name: 'country_short_name',
            },
            postal_code: {
                short_name: 'pincode'
            },
        };
        this.selectedAddressDetails.types = place?.types;
        this.selectedAddressDetails.lat = place?.geometry?.location.lat();
        this.selectedAddressDetails.long = place?.geometry?.location.lng();
        this.selectedAddressDetails.utc_offset = place?.utc_offset_minutes;
        this.selectedAddressDetails.google_place_id = place?.place_id;
        this.selectedAddressDetails.google_map_url = place?.url;
        var appComponent = place?.address_components;
        appComponent?.forEach((element: any) => {
            for (var component in componentForm) {
                if (element.types.includes(component)) {
                    for (var component2 in componentForm[component]) {

                        this.selectedAddressDetails[componentForm[component][component2]] = element[component2]
                    }
                }
            }
        });

        this.selectedAddressDetails.formatted_address = (place.name ? place.name + ' ' : '') + place.formatted_address;
        // if (constant.live) {
        //     this.selectedAddressDetails.district_short_name = this.selectedAddressDetails.district_short_name ? this.selectedAddressDetails.district_short_name : this.selectedAddressDetails.state_short_name;
        //     this.selectedAddressDetails.district_name = this.selectedAddressDetails.district_name ? this.selectedAddressDetails.district_name : this.selectedAddressDetails.state_name;
        // }
        return this.getCityNameFromOtherLocality(this.selectedAddressDetails);

        // })
    }

    getCityNameFromOtherLocality(cityDtl: any) {
        switch (true) {
            case (cityDtl.types.includes('locality')):
                cityDtl.sublocality_type = 'locality';
                cityDtl.city_name = cityDtl.locality;
                break;
            case (cityDtl.types.includes('sublocality_level_1')):
                cityDtl.sublocality_type = 'sublocality_level_1';
                cityDtl.city_name = cityDtl.sublocality_level_1;
                break;
            case (cityDtl.types.includes('sublocality_level_2')):
                cityDtl.sublocality_type = 'sublocality_level_2';
                cityDtl.city_name = cityDtl.sublocality_level_2;
                break;
            case (cityDtl.types.includes('neighborhood')):
                cityDtl.sublocality_type = 'neighborhood';
                cityDtl.city_name = cityDtl.neighborhood;
                break;
            default:
                if (cityDtl.locality) {
                    cityDtl.sublocality_type = 'locality';
                    cityDtl.city_name = cityDtl.locality;
                } else if (cityDtl.sublocality_level_1) {
                    cityDtl.sublocality_type = 'sublocality_level_1';
                    cityDtl.city_name = cityDtl.sublocality_level_1;
                } else if (cityDtl.sublocality_level_2) {
                    cityDtl.sublocality_type = 'sublocality_level_2';
                    cityDtl.city_name = cityDtl.sublocality_level_2;
                } else if (cityDtl.neighborhood) {
                    cityDtl.sublocality_type = 'neighborhood';
                    cityDtl.city_name = cityDtl.neighborhood;
                }
        }
        return cityDtl;
    }

}

let googleService = new GoogleService();
export default googleService;
