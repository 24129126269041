import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import constant from 'src/app/app.constant';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    // Navbar Sticky
    isSticky: boolean = false;
    activeFragment: string;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(private router: Router,private route: ActivatedRoute) {
        this.route.fragment.subscribe((fragment) => {
            if (fragment) {
                this.activeFragment = fragment;
            } else {
                this.activeFragment = '';
            }
          });
    }
    
    ngOnInit(): void {
    }
    customerForm() {
        this.router.navigate(['/customer/'+ constant.Service.MainLogin.integrateId])
      }
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    setActiveFragment(fragment: string): void {
        this.activeFragment = fragment;
      }

}