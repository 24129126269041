<div class="trainer-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Instructor</span>
            <h2>TEAM OF EXPERT COACHES</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="trainer-slides">
            <owl-carousel-o [options]="trainerSlides">
                <ng-template carouselSlide>
                    <div class="single-trainer-box">
                        <img src="assets/img/dotted.png" alt="image">
                        <span class="designation">AERIAL EXPERT</span>
                        <div class="content">
                            <h3>ALYSA MAYA</h3>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-trainer-box">
                        <img src="assets/img/dotted.png" alt="image">
                        <span class="designation">BARRE EXPERT</span>
                        <div class="content">
                            <h3>SARAH TAYLOR</h3>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-trainer-box">
                        <img src="assets/img/trainer/trainer-img3.jpg" alt="image">
                        <span class="designation">BOOTCAMP EXPERT</span>
                        <div class="content">
                            <h3>JAMES ANDY</h3>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-trainer-box">
                        <img src="assets/img/trainer/trainer-img4.jpg" alt="image">
                        <span class="designation">CROSSFIT EXPERT</span>
                        <div class="content">
                            <h3>LINA LUCY</h3>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-trainer-box">
                        <img src="assets/img/trainer/trainer-img5.jpg" alt="image">
                        <span class="designation">GYM EXPERT</span>
                        <div class="content">
                            <h3>ALINA STIVE</h3>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>