<!-- adloggs version 0.1 -->
<div
    class="container"
    [ngClass]="{'d-none': router.url === '/online-art' || router.url === '/single-instructor'}"
>
    <div class="about-content-box">
        <h2>Blogs that Make <br class="ai-break"/>You Think</h2>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="post-image">
                    <a routerLink="/blogs/impact-of-late-and-inaccurate-deliveries-component" class="d-block">
                        <img src="assets/img/blog/impact-of-late-and-inaccurate-deliveries.jpg" alt="impact-of-late-and-inaccurate-deliveries">
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blogs/impact-of-late-and-inaccurate-deliveries-component" class="category">Delivery Management System (DMS)</a>
                    <h3><a routerLink="/blogs/impact-of-late-and-inaccurate-deliveries-component">Impact of Late and Inaccurate Deliveries</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <a href="https://www.linkedin.com/in/darshan-krishnasamy-80039b7b/" target="_blank" class="post-author d-flex align-items-center">
                                <img src="assets/img/Darshan-Krishnasamy.jpeg" class="rounded-circle" alt="author">
                                <span>Darshan Krishnasamy</span>
                            </a>
                        </li>
                        <li><small>20 July, 2024</small></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="post-image">
                    <a routerLink="/blogs/top-features-for-delivery-management-system" class="d-block">
                        <img src="assets/img/blog/top-features-for-delivery-management-system.jpg" alt="top-features-for-delivery-management-system">
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blogs/top-features-for-delivery-management-system" class="category">Delivery Management System (DMS)</a>
                    <h3><a routerLink="/blogs/top-features-for-delivery-management-system">Top Features Your Delivery Management System Should Have</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <a href="https://www.linkedin.com/in/darshan-krishnasamy-80039b7b/" target="_blank" class="post-author d-flex align-items-center">
                                <img src="assets/img/Darshan-Krishnasamy.jpeg" class="rounded-circle" alt="author">
                                <span>Darshan Krishnasamy</span>
                            </a>
                        </li>
                        <li><small>15 June, 2024</small></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="post-image">
                    <a routerLink="/blogs/what-is-delivery-orchestration-system" class="d-block">
                        <img src="assets/img/blog/what-is-delivery-orchestration-system.jpg" alt="what-is-delivery-orchestration-system">
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blogs/what-is-delivery-orchestration-system" class="category">Delivery Orchestration System</a>
                    <h3><a routerLink="/blogs/what-is-delivery-orchestration-system">Delivery Orchestration System - All You Need To Know</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <a href="https://www.linkedin.com/in/darshan-krishnasamy-80039b7b/" target="_blank" class="post-author d-flex align-items-center">
                                <img src="assets/img/Darshan-Krishnasamy.jpeg" class="rounded-circle" alt="author">
                                <span>Darshan Krishnasamy</span>
                            </a>
                        </li>
                        <li><small>10 May, 2024</small></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="blog-post-info">
                <p>Get into details now?​ <a routerLink="/blogs">View all blogs</a></p>
            </div>
        </div>
    </div>
</div>
