<div class="view-all-courses-area bg-fef8ef">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>
                        Feel Like You Are Attending Your Classes Physically!
                    </h2>
                    <p>
                        adloggs training programs can bring you a super exciting
                        experience of learning through online! You never face
                        any negative experience while enjoying your classes
                        virtually by sitting in your comfort zone. Our flexible
                        learning initiatives will help you to learn better and
                        quicker than the traditional ways of learning skills.
                    </p>
                    <a routerLink="/courses-1" class="default-btn"
                        ><i class="flaticon-agenda"></i>View Courses<span></span
                    ></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-image">
                    <img src="assets/img/man-with-laptop.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image" /></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>
