import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-components',
  // standalone: true,
  // imports: [],
  templateUrl: './faq-components.component.html',
  styleUrl: './faq-components.component.scss'
})
export class FaqComponentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

  // Accordion
  contentHeight: number = 0;
  openSectionIndex: number = -1;
  toggleSection(index: number): void {
      if (this.openSectionIndex === index) {
          this.openSectionIndex = -1;
      } else {
          this.openSectionIndex = index;
          this.calculateContentHeight();
      }
  }
  isSectionOpen(index: number): boolean {
      return this.openSectionIndex === index;
  }
  calculateContentHeight(): void {
      const contentElement = document.querySelector('.accordion-content');
      if (contentElement) {
          this.contentHeight = contentElement.scrollHeight;
      }
  }
}

