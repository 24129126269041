import { Component } from '@angular/core';

@Component({
  selector: 'app-customer',
  // standalone: true,
  // imports: [],
  templateUrl: './customer.component.html',
  styleUrl: './customer.component.scss'
})
export class CustomerComponent {

}
