import { Component, NgZone, OnInit, Renderer2 } from '@angular/core';
import lgVideo from 'lightgallery/plugins/video';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { AboutUsComponent } from "../about-us/about-us.component";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import constant from 'src/app/app.constant';

@Component({
  selector: 'app-business',
  //standalone: true,
  //imports: [AboutUsComponent],
  templateUrl: './business.component.html',
  styleUrl: './business.component.scss'
})
  
export class BusinessComponent implements OnInit  {

  constructor(private router: Router,private route: ActivatedRoute, private viewportScroller: ViewportScroller) { }
  ngOnInit(): void {

  }
  customerForm() {
    this.router.navigate(['/customer/'+ constant.Service.MainLogin.integrateId])
  }
  ngAfterViewInit() {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor(fragment);
        }, 0);
      }
    });
  }

  settings = {
      counter: false,
      plugins: [lgVideo]
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
      const { index, prevIndex } = detail;
      console.log(index, prevIndex);
  };
}

