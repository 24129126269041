<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/kfc.png" alt="kFC">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/a2b.png" alt="a2b">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/pizzahut.png" alt="pizzahut">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/magicpin.png" alt="magicpin">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/paytm.png" alt="paytm">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/taco-bell.png" alt="taco-bell">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/petpooja.png" alt="petpooja">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/clickpost.png" alt="clickpost">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/wow-momo.png" alt="WowMomo">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/spar.png" alt="spar">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/urpen-piper.png" alt="urpenPiper">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/nstore.png" alt="nstore">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/hoi-foods.png" alt="hoi-foods">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/maamisamall.png" alt="maamisamall">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/homeal.png" alt="homeal">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/tazzapanta.png" alt="tazzapanta">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/dominos.png" alt="dominos">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/layaji.png" alt="layaji">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/clients/retos.png" alt="retos">
            </div>
        </ng-template>
    </owl-carousel-o>
</div>