import { Component } from '@angular/core';

@Component({
  selector: 'app-on-demand-delivery-marketplace',
  templateUrl: './on-demand-delivery-marketplace.component.html',
  styleUrl: './on-demand-delivery-marketplace.component.scss'
})
export class OnDemandDeliveryMarketplaceComponent {

}
