import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LightgalleryModule } from 'lightgallery/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { PremiumAccessComponent } from './components/common/premium-access/premium-access.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { EventsComponent } from './components/pages/events/events.component';
import { FeaturesStyleOneComponent } from './components/common/features-style-one/features-style-one.component';
import { ViewAllCoursesComponent } from './components/common/view-all-courses/view-all-courses.component';
import { GetInstantCoursesComponent } from './components/common/get-instant-courses/get-instant-courses.component';
import { FunfactsFeedbackComponent } from './components/common/funfacts-feedback/funfacts-feedback.component';
import { VideoComponent } from './components/common/video/video.component';
import { CoursesComponent } from './components/common/courses/courses.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { ViewAllCoursesTwoComponent } from './components/common/view-all-courses-two/view-all-courses-two.component';
import { SloganComponent } from './components/common/slogan/slogan.component';
import { InstructorComponent } from './components/common/instructor/instructor.component';
import { TestimonialsComponent } from './components/common/testimonials/testimonials.component';
import { PremiumAccessTwoComponent } from './components/common/premium-access-two/premium-access-two.component';
import { FunfactsStyleOneComponent } from './components/common/funfacts-style-one/funfacts-style-one.component';
import { FeaturesStyleTwoComponent } from './components/common/features-style-two/features-style-two.component';
import { InstructorStyleTwoComponent } from './components/common/instructor-style-two/instructor-style-two.component';
import { TopCategoriesComponent } from './components/common/top-categories/top-categories.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FunfactsStyleTwoComponent } from './components/common/funfacts-style-two/funfacts-style-two.component';
import { FeedbackWithBgComponent } from './components/common/feedback-with-bg/feedback-with-bg.component';
import { HowToApplyComponent } from './components/common/how-to-apply/how-to-apply.component';
import { FreeTrialComponent } from './components/common/free-trial/free-trial.component';
import { UpcomingEventsComponent } from './components/common/upcoming-events/upcoming-events.component';
import { BoxesComponent } from './components/common/boxes/boxes.component';
import { OverviewComponent } from './components/common/overview/overview.component';
import { TrainingComponent } from './components/common/training/training.component';
import { FeedbackStyleTwoComponent } from './components/common/feedback-style-two/feedback-style-two.component';
import { DownloadSyllabusComponent } from './components/common/download-syllabus/download-syllabus.component';
import { FlexiblePricingComponent } from './components/common/flexible-pricing/flexible-pricing.component';
import { SubscribeStyleTwoComponent } from './components/common/subscribe-style-two/subscribe-style-two.component';
import { ExperienceComponent } from './components/common/experience/experience.component';
import { LifestyleComponent } from './components/common/lifestyle/lifestyle.component';
import { ProgramComponent } from './components/common/program/program.component';
import { ServicesComponent } from './components/common/services/services.component';
import { SubscribeStyleThreeComponent } from './components/common/subscribe-style-three/subscribe-style-three.component';
import { SubscribeStyleFourComponent } from './components/common/subscribe-style-four/subscribe-style-four.component';
import { SelectedAgesComponent } from './components/common/selected-ages/selected-ages.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { OnlinePlatformComponent } from './components/common/online-platform/online-platform.component';
import { FeaturedComponent } from './components/common/featured/featured.component';
import { WhyChooseUsComponent } from './components/common/why-choose-us/why-choose-us.component';
import { FunfactsStyleThreeComponent } from './components/common/funfacts-style-three/funfacts-style-three.component';
import { FeedbackStyleThreeComponent } from './components/common/feedback-style-three/feedback-style-three.component';
import { ExpertTrainerComponent } from './components/common/expert-trainer/expert-trainer.component';
import { ApplyInstructorComponent } from './components/common/apply-instructor/apply-instructor.component';
import { TeacherRegisterComponent } from './components/common/teacher-register/teacher-register.component';
import { SuccessPeopleComponent } from './components/common/success-people/success-people.component';
import { FeaturesStyleFourComponent } from './components/common/features-style-four/features-style-four.component';
import { TestimonialsStyleTwoComponent } from './components/common/testimonials-style-two/testimonials-style-two.component';
import { GetInstantCoursesTwoComponent } from './components/common/get-instant-courses-two/get-instant-courses-two.component';
import { PartnerSliderComponent } from './components/common/partner-slider/partner-slider.component';
import { VideoStyleTwoComponent } from './components/common/video-style-two/video-style-two.component';
import { FunfactsStyleFourComponent } from './components/common/funfacts-style-four/funfacts-style-four.component';
import { FeaturesStyleThreeComponent } from './components/common/features-style-three/features-style-three.component';
import { CoursesTypesComponent } from './components/common/courses-types/courses-types.component';
import { FunfactsStyleFiveComponent } from './components/common/funfacts-style-five/funfacts-style-five.component';
import { FeedbackStyleFourComponent } from './components/common/feedback-style-four/feedback-style-four.component';
import { CoachingProgramComponent } from './components/common/coaching-program/coaching-program.component';
import { FeaturesFunfactsComponent } from './components/common/features-funfacts/features-funfacts.component';
import { AboutMeComponent } from './components/common/about-me/about-me.component';
import { CoachingProgramStyleTwoComponent } from './components/common/coaching-program-style-two/coaching-program-style-two.component';
import { SubscribeStyleFiveComponent } from './components/common/subscribe-style-five/subscribe-style-five.component';
import { FeaturesStyleFiveComponent } from './components/common/features-style-five/features-style-five.component';
import { DownloadBookComponent } from './components/common/download-book/download-book.component';
import { MyNewsletterComponent } from './components/common/my-newsletter/my-newsletter.component';
import { GetStartedComponent } from './components/common/get-started/get-started.component';
import { InstructorQuoteComponent } from './components/common/instructor-quote/instructor-quote.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { ThreeplSectionComponent } from './components/common/home/threepl-section/threepl-section.component';
import { StorySectionComponent } from './components/common/home/story-section/story-section.component';
import { AnimationDataSectionComponent } from './components/common/home/animation-data-section/animation-data-section.component';
import { BusinessComponent } from './components/pages/business/business.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { SolutionsComponent } from './components/pages/solutions/solutions.component';
import { FaqComponentsComponent } from './components/common/faq-components/faq-components.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HomeBannerComponent } from './components/pages/home/home-banner/home-banner.component';
import { SuccessfullyDeliveredComponent } from './components/common/successfully-delivered/successfully-delivered.component';
import { FormsComponent } from './components/pages/forms/forms.component';
import { CustomerComponent } from './components/pages/forms/customer/customer.component';
import { PartnerDmsComponent } from './components/pages/forms/partner-dms/partner-dms.component';
import { PolicyComponent } from './components/pages/policy/policy.component';
import { PrivacyPolicyComponent } from './components/pages/policy/privacy-policy/privacy-policy.component';
import { BlogsComponent } from './components/pages/blogs/blogs.component';
import { RegisterSuccessfullyComponent } from './components/pages/register-successfully/register-successfully.component';
import { ImpactOfLateAndInaccurateDeliveriesComponent } from './components/pages/blogs/blog-details/impact-of-late-and-inaccurate-deliveries/impact-of-late-and-inaccurate-deliveries.component';
import { TopFeaturesForDeliveryManagementSystemComponent } from './components/pages/blogs/blog-details/top-features-for-delivery-management-system/top-features-for-delivery-management-system.component';
import { WhatIsDeliveryOrchestrationSystemComponent } from './components/pages/blogs/blog-details/what-is-delivery-orchestration-system/what-is-delivery-orchestration-system.component';
import { OnDemandDeliveryMarketplaceComponent } from './components/pages/blogs/blog-details/on-demand-delivery-marketplace/on-demand-delivery-marketplace.component';
import { DeliveryManagementSoftwareComponent } from './components/pages/blogs/blog-details/delivery-management-software/delivery-management-software.component';
import { WhatIsFoodDeliverySoftwareComponent } from './components/pages/blogs/blog-details/what-is-food-delivery-software/what-is-food-delivery-software.component';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NumberDirective } from './utils/numbersOnly.directive';

@NgModule({
  declarations: [
    NumberDirective,
    AppComponent,
    NavbarComponent,
    FooterComponent,
    PartnerComponent,
    PremiumAccessComponent,
    SubscribeComponent,
    ContactUsComponent,
    ErrorComponent,
    ComingSoonComponent,
    EventsComponent,
    FeaturesStyleOneComponent,
    ViewAllCoursesComponent,
    GetInstantCoursesComponent,
    FunfactsFeedbackComponent,
    VideoComponent,
    CoursesComponent,
    BlogComponent,
    ViewAllCoursesTwoComponent,
    SloganComponent,
    InstructorComponent,
    TestimonialsComponent,
    PremiumAccessTwoComponent,
    FunfactsStyleOneComponent,
    FeaturesStyleTwoComponent,
    InstructorStyleTwoComponent,
    TopCategoriesComponent,
    FeedbackComponent,
    FunfactsStyleTwoComponent,
    FeedbackWithBgComponent,
    HowToApplyComponent,
    FreeTrialComponent,
    UpcomingEventsComponent,
    BoxesComponent,
    OverviewComponent,
    TrainingComponent,
    FeedbackStyleTwoComponent,
    DownloadSyllabusComponent,
    FlexiblePricingComponent,
    SubscribeStyleTwoComponent,
    ExperienceComponent,
    LifestyleComponent,
    ProgramComponent,
    ServicesComponent,
    SubscribeStyleThreeComponent,
    SubscribeStyleFourComponent,
    SelectedAgesComponent,
    AppDownloadComponent,
    OnlinePlatformComponent,
    FeaturedComponent,
    WhyChooseUsComponent,
    FunfactsStyleThreeComponent,
    FeedbackStyleThreeComponent,
    ExpertTrainerComponent,
    ApplyInstructorComponent,
    TeacherRegisterComponent,
    SuccessPeopleComponent,
    FeaturesStyleFourComponent,
    TestimonialsStyleTwoComponent,
    GetInstantCoursesTwoComponent,
    PartnerSliderComponent,
    VideoStyleTwoComponent,
    FunfactsStyleFourComponent,
    FeaturesStyleThreeComponent,
    CoursesTypesComponent,
    FunfactsStyleFiveComponent,
    FeedbackStyleFourComponent,
    CoachingProgramComponent,
    FeaturesFunfactsComponent,
    AboutMeComponent,
    CoachingProgramStyleTwoComponent,
    SubscribeStyleFiveComponent,
    FeaturesStyleFiveComponent,
    DownloadBookComponent,
    MyNewsletterComponent,
    GetStartedComponent,
    InstructorQuoteComponent,
    FeaturesComponent,

    BusinessComponent,
    AboutUsComponent,
    FaqComponentsComponent,
    HomeComponent,
    SolutionsComponent,
    HomeBannerComponent,
    ThreeplSectionComponent,
    StorySectionComponent,
    AnimationDataSectionComponent,
    SuccessfullyDeliveredComponent,
    FormsComponent,
    CustomerComponent,
    PartnerDmsComponent,
    BlogsComponent,
    PolicyComponent,
    PrivacyPolicyComponent,
    RegisterSuccessfullyComponent,
    ImpactOfLateAndInaccurateDeliveriesComponent,
    TopFeaturesForDeliveryManagementSystemComponent,
    WhatIsDeliveryOrchestrationSystemComponent,
    OnDemandDeliveryMarketplaceComponent,
    DeliveryManagementSoftwareComponent,
    WhatIsFoodDeliverySoftwareComponent,
  ],

  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    CountUpModule,
    NgxScrollTopModule,
    LightboxModule,
    FormsModule,
    LightgalleryModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgOtpInputModule,
    NgbModule
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyCm96Z5NH4-7uWsEEykk0f_N0UwGBaFKU8',
    //   libraries: ['places']
    // }),
    // NgSelectModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }