import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { EventsComponent } from './components/pages/events/events.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { BusinessComponent } from './components/pages/business/business.component';
import { HomeComponent } from './components/pages/home/home.component';
import { SolutionsComponent } from './components/pages/solutions/solutions.component';
import { FormsComponent } from './components/pages/forms/forms.component';
import { PartnerDmsComponent } from './components/pages/forms/partner-dms/partner-dms.component';
import { BlogsComponent } from './components/pages/blogs/blogs.component';
import { PolicyComponent } from './components/pages/policy/policy.component';
import { PrivacyPolicyComponent } from './components/pages/policy/privacy-policy/privacy-policy.component';
import { RegisterSuccessfullyComponent } from './components/pages/register-successfully/register-successfully.component';

import { ImpactOfLateAndInaccurateDeliveriesComponent } from './components/pages/blogs/blog-details/impact-of-late-and-inaccurate-deliveries/impact-of-late-and-inaccurate-deliveries.component';
import { TopFeaturesForDeliveryManagementSystemComponent } from './components/pages/blogs/blog-details/top-features-for-delivery-management-system/top-features-for-delivery-management-system.component';
import { WhatIsDeliveryOrchestrationSystemComponent } from './components/pages/blogs/blog-details/what-is-delivery-orchestration-system/what-is-delivery-orchestration-system.component';
import { OnDemandDeliveryMarketplaceComponent } from './components/pages/blogs/blog-details/on-demand-delivery-marketplace/on-demand-delivery-marketplace.component';
import { DeliveryManagementSoftwareComponent } from './components/pages/blogs/blog-details/delivery-management-software/delivery-management-software.component';
import { WhatIsFoodDeliverySoftwareComponent } from './components/pages/blogs/blog-details/what-is-food-delivery-software/what-is-food-delivery-software.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'customer/:id', component: FormsComponent },
    { path: 'partner-dms', component: PartnerDmsComponent },
    { path: 'company', component: AboutUsComponent },
    { path: 'blogs', component: BlogsComponent },
    { path: 'business', component: BusinessComponent },
    { path: 'solutions', component: SolutionsComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'register-successfully', component: RegisterSuccessfullyComponent },
    { path: 'terms-and-conditions', component: PolicyComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'blogs/impact-of-late-and-inaccurate-deliveries-component', component: ImpactOfLateAndInaccurateDeliveriesComponent },
    { path: 'blogs/top-features-for-delivery-management-system', component: TopFeaturesForDeliveryManagementSystemComponent },
    { path: 'blogs/what-is-delivery-orchestration-system', component: WhatIsDeliveryOrchestrationSystemComponent },
    { path: 'blogs/on-demand-delivery-marketplace', component: OnDemandDeliveryMarketplaceComponent },
    { path: 'blogs/delivery-management-software', component: DeliveryManagementSoftwareComponent },
    { path: 'blogs/what-is-food-delivery-software', component: WhatIsFoodDeliverySoftwareComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'events', component: EventsComponent },
    // Here add new pages component

    { path: '**', component: ErrorComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }