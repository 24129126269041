import { Component } from '@angular/core';

@Component({
  selector: 'app-top-features-for-delivery-management-system',
  // standalone: true,
  // imports: [],
  templateUrl: './top-features-for-delivery-management-system.component.html',
  styleUrl: './top-features-for-delivery-management-system.component.scss'
})
export class TopFeaturesForDeliveryManagementSystemComponent {
  constructor() { }
  ngOnInit(): void {}
}
