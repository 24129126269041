<div class="container">
    <div class="section-title">
        <span class="sub-title">Testimonials</span>
        <h2>What People Say About adloggs</h2>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
    </div>
    <div class="testimonials-slides">
        <owl-carousel-o [options]="testimonialsSlides">
            <ng-template carouselSlide>
                <div class="single-testimonials-item">
                    <img
                        src="assets/img/user1.jpg"
                        class="client-img"
                        alt="image"
                    />
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum ultrices gravida. Risus commodo
                        viverra maecenas accumsan lacus vel facilisis. Lorem
                        ipsum dolor sit amet, consectetur adipiscing elit, sed
                        tempor incididunt ut labore et dolore.
                    </p>
                    <h3>John Smith</h3>
                    <span>Python Developer</span>
                    <div class="shape-img">
                        <img
                            src="assets/img/shape4.png"
                            class="shape-1"
                            alt="image"
                        />
                        <img
                            src="assets/img/shape14.png"
                            class="shape-2"
                            alt="image"
                        />
                        <img
                            src="assets/img/shape7.png"
                            class="shape-3"
                            alt="image"
                        />
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-testimonials-item">
                    <img
                        src="assets/img/user2.jpg"
                        class="client-img"
                        alt="image"
                    />
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum ultrices gravida. Risus commodo
                        viverra maecenas accumsan lacus vel facilisis. Lorem
                        ipsum dolor sit amet, consectetur adipiscing elit, sed
                        tempor incididunt ut labore et dolore.
                    </p>
                    <h3>Sarah Taylor</h3>
                    <span>PHP Developer</span>
                    <div class="shape-img">
                        <img
                            src="assets/img/shape4.png"
                            class="shape-1"
                            alt="image"
                        />
                        <img
                            src="assets/img/shape14.png"
                            class="shape-2"
                            alt="image"
                        />
                        <img
                            src="assets/img/shape7.png"
                            class="shape-3"
                            alt="image"
                        />
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-testimonials-item">
                    <img
                        src="assets/img/user3.jpg"
                        class="client-img"
                        alt="image"
                    />
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum ultrices gravida. Risus commodo
                        viverra maecenas accumsan lacus vel facilisis. Lorem
                        ipsum dolor sit amet, consectetur adipiscing elit, sed
                        tempor incididunt ut labore et dolore.
                    </p>
                    <h3>David Warner</h3>
                    <span>QA Developer</span>
                    <div class="shape-img">
                        <img
                            src="assets/img/shape4.png"
                            class="shape-1"
                            alt="image"
                        />
                        <img
                            src="assets/img/shape14.png"
                            class="shape-2"
                            alt="image"
                        />
                        <img
                            src="assets/img/shape7.png"
                            class="shape-3"
                            alt="image"
                        />
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
