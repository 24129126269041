<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blogs</li>
            </ul>
            <h2>Check our inside news & update</h2>
        </div>
    </div>
</div>

<div class="events-area pb-70">
    <div class="container">
        <div class="events-tabs">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        All
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        Delivery Management System (DMS)
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        Food Delivery
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        Delivery Orchestration System 
                    </span>
                </li>
            </ul>
            <div class="tabs-container">

                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/impact-of-late-and-inaccurate-deliveries-component" class="d-block">
                                        <img src="assets/img/blog/impact-of-late-and-inaccurate-deliveries.jpg" alt="impact-of-late-and-inaccurate-deliveries">
                                    </a>
                                    <span class="date">20 July, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/impact-of-late-and-inaccurate-deliveries-component">Impact of Late and Inaccurate Deliveries</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/top-features-for-delivery-management-system" class="d-block">
                                        <img src="assets/img/blog/top-features-for-delivery-management-system.jpg" alt="top-features-for-delivery-management-system">
                                    </a>
                                    <span class="date">15 June, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/top-features-for-delivery-management-system">Top Features Your Delivery Management System Should Have</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/what-is-delivery-orchestration-system" class="d-block">
                                        <img src="assets/img/blog/what-is-delivery-orchestration-system.jpg" alt="what-is-delivery-orchestration-system">
                                    </a>
                                    <span class="date">10 May, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/what-is-delivery-orchestration-system">Delivery Orchestration System - All You Need To Know</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/on-demand-delivery-marketplace" class="d-block">
                                        <img src="assets/img/blog/on-demand-delivery-marketplace.jpg" alt="on-demand-delivery-marketplace">
                                    </a>
                                    <span class="date">02 May, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/on-demand-delivery-marketplace">Everything You Need to Know About the On Demand Delivery Marketplace.</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/delivery-management-software" class="d-block">
                                        <img src="assets/img/blog/delivery-management-software.jpg" alt="delivery-management-software">
                                    </a>
                                    <span class="date">20 April, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/delivery-management-software">Delivery Management Software: A Complete Guide</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/what-is-food-delivery-software" class="d-block">
                                        <img src="assets/img/blog/what-is-food-delivery-software.jpg" alt="what-is-food-delivery-software">
                                    </a>
                                    <span class="date">10 April, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/what-is-food-delivery-software">Food Delivery Software - What is it?</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/impact-of-late-and-inaccurate-deliveries" class="d-block">
                                        <img src="assets/img/blog/impact-of-late-and-inaccurate-deliveries.jpg" alt="impact-of-late-and-inaccurate-deliveries">
                                    </a>
                                    <span class="date">20 July, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/impact-of-late-and-inaccurate-deliveries">Impact of Late and Inaccurate Deliveries</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/top-features-for-delivery-management-system" class="d-block">
                                        <img src="assets/img/blog/top-features-for-delivery-management-system.jpg" alt="top-features-for-delivery-management-system">
                                    </a>
                                    <span class="date">15 June, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/top-features-for-delivery-management-system">Top Features Your Delivery Management System Should Have</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/what-is-delivery-orchestration-system" class="d-block">
                                        <img src="assets/img/blog/what-is-delivery-orchestration-system.jpg" alt="what-is-delivery-orchestration-system">
                                    </a>
                                    <span class="date">10 May, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/what-is-delivery-orchestration-system">Delivery Orchestration System - All You Need To Know</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/on-demand-delivery-marketplace" class="d-block">
                                        <img src="assets/img/blog/on-demand-delivery-marketplace.jpg" alt="on-demand-delivery-marketplace">
                                    </a>
                                    <span class="date">02 May, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/on-demand-delivery-marketplace">Everything You Need to Know About the On Demand Delivery Marketplace.</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/delivery-management-software" class="d-block">
                                        <img src="assets/img/blog/delivery-management-software.jpg" alt="delivery-management-software">
                                    </a>
                                    <span class="date">20 April, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/delivery-management-software">Delivery Management Software: A Complete Guide</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/what-is-food-delivery-software" class="d-block">
                                        <img src="assets/img/blog/what-is-food-delivery-software.jpg" alt="what-is-food-delivery-software">
                                    </a>
                                    <span class="date">10 April, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/what-is-food-delivery-software">Food Delivery Software - What is it?</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pane" id="tab3" *ngIf="currentTab === 'tab4'">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6">
                            <div class="single-events-box">
                                <div class="image">
                                    <a routerLink="/blogs/what-is-delivery-orchestration-system" class="d-block">
                                        <img src="assets/img/blog/what-is-delivery-orchestration-system.jpg" alt="what-is-delivery-orchestration-system">
                                    </a>
                                    <span class="date">10 May, 2024</span>
                                </div>
                                <div class="content">
                                    <h3><a routerLink="/blogs/what-is-delivery-orchestration-system">Delivery Orchestration System - All You Need To Know</a></h3>
                                    <span class="location"><i class="bx bx-user"></i>Darshan Krishnasamy</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
