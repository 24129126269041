<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="success-container">
                <img class="ondc" src="assets/img/icons/ondc.png" alt="ONDC">                
                <h1 class="mt-4">No 1 in ONDC</h1>
                <h2>Direct Partnerships : Empowering India's Digital Commerce</h2>
                <img class="order-img" src="assets/img/ondc-connect.png" alt="OndcConnect">
            </div>            
        </div>
    </div>
</div>