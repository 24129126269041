<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Solutions</li>
            </ul>
            <h2>Adloggs is the ultimate delivery software that helps you manage deliveries easier and deliver on your brand</h2>
        </div>
    </div>
</div>
<!-- <div class="banner-container">
    <img src="../assets/img/banners/business.jpg" alt="bannerImage">
</div> -->
<div class="pt-70 pb-70">
    <div class="container">
        <div class="success-container">
            <div class="row">
                <div class="col-lg-5 col-md-12"> 
                    <div class="d-block">
                            <h2 class="mb-4 ad-title-h2">Delivery Orchestration System (DOS)</h2>
                            <h5 class="mb-0 ad-baout-content">Adloggs introduces a new approach to delivery management. When an order is received, it evaluates several factors before assigning it to a rider. The system then tracks the package throughout the delivery process, using advanced AI to predict and prevent potential issues, ensuring a smooth and reliable delivery experience.</h5>
                            <h2 class="mb-4 mt-4 ad-title-h2">Features</h2>
                            <p>1. Riders searched and matched within 10 Seconds</p>
                            <p>2. Ride Tracked by AI</p>
                            <p>3. Order Success Prediction</p>
                            <p>4. Auto Cancellation</p>
                            <p>5. Rebooking</p>                             
                    </div>         
                </div>                    
                <div class="col-lg-7 col-md-12"> 
                    <div class="text-end ad-about-img">
                        <object data="assets/img/AdloggsDOS.svg" alt="about-adloggs"></object>
                    </div>        
                </div> 
            </div>
        </div>
    </div>
</div>

<!-- faq -->
<div class="pb-70">
<app-faq-components></app-faq-components>
</div>